interface props{
  isPrivateChat?:boolean;
}
export const PromptIcon = ({isPrivateChat}:props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
    <path d="M18.8976 25.2281C23.6475 25.2281 26.4472 22.5625 26.4472 18.0402L26.4844 11.7648V7.36475L20.2254 1H10.1506H7.49555C2.74436 1 1 3.66561 1 8.18161V18.0402C1 22.5625 3.80505 25.2281 8.56685 25.2281H18.8976Z" stroke={isPrivateChat?"#333C7A":"#A09FA2"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.59766 6.28094H16.4313" stroke={isPrivateChat?"#333C7A":"#A09FA2"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="13.7254" cy="16.0315" r="3.79177" fill={isPrivateChat?"#333C7A":"#A09FA2"} />
  </svg>
);
