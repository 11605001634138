import { useState, useEffect, useCallback, useRef, memo, useMemo } from "react";
import { useEffectOnce } from "react-use";
import styles from "./ChatPage.module.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import { useIntl } from "react-intl";

import classNames from "classnames";
import ReactGA from "react-ga4";

import { MainView } from "views/layout/MainView";
import { NewChat, ChatHistory, IErrorMessage } from "./pages";
import _ from "lodash";
import {
  ChatFooter,
  ChatFooterMethods,
} from "./components/chatFooter/chatFooter";
import { CreditLimitModal } from "./components/creditLimitModal";
import { ErrorModal } from "./components/ErrorModal";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useNetwork } from "hooks/services/NetworkProvider";

import {
  startNewChat,
  askNewChatQuestion,
  answerNewChatQuestion,
  askRegularQuestion,
  answerRegularQuestion,
  updateCredits,
  updateCreditLimit,
  getChatHistory,
  getActivePlan,
  stopGeneratingResponse,
  IUser,
  IMessage,
  generateRelatedQuestions,
  removeRelatedQuestions,
  generateImage,
  getNewChatTopic,
  setChatModel,
  EChatType,
  ChatType,
  IChat,
  getChatSettings,
  IChatModel,
  IImage,
  IFile,
  updateNewRegularQuestion,
  updatedRegularQuestion,
  SettingsBase,
  removeMultipleChat,
  setDropDownOptions,
  setRememberSetting,
  // setSelectedSubOptions,
} from "redux/actions";
import { useChatStream } from "hooks/services/ChatServices";
import { useSelector } from "redux/hooks";
import { RoutePaths } from "pages/routePaths";
import { ShareChat } from "./pages/ShareChatPage";
import { ChatSettingModal } from "./components/chatSettingModal";
import { DocumentModal } from "./pages/newChat/components/DocumentModal";
import { ErrorMessages } from "utils/functions";
import { FileSizeLimitModal } from "./components/fileSizeLimitModal";
import { getDropdownOptions } from "utils/constants";

// interfaces
interface IRoute {
  path?: string;
  component: React.ReactNode;
}

export const ChatRoute = {
  New: "new",
  History: "history",
} as const;

export interface IGetMessageAgainstKey {
  messagesArray: IMessage[];
  key: any;
}

export interface IUploadFile {
  file: File,
  status: 'validating' | 'uploading' | 'uploaded' | 'error',
  fileType: 'document' | 'image',
  S3Link?: string,
}

export interface SelectedSubOptions {
  [key: string]: string;
}

export const ChatPage = memo(() => {
  // custom hooks
  const {
    done,
    setDone,
    startStream,
    controllerRef,
    chatHistoryPagination,
    setChatHistory,
  } = useChatStream();
  const { isOnline } = useNetwork();
  const { includeRoute, pathname, push } = useRouter();
  const chatId = pathname.split("/")[3];
  const { triggerNotification } = useAppNotification();
  const { formatMessage } = useIntl();

  // data from reducer
  const chatModel = useSelector((state) => state.authReducer.gptModel);
  const chatHistory = useSelector((state) => state.chatReducer.chats);
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { newMessages, messages, chatSetting, PrivateChat } = useSelector(
    (state) => state.chatReducer
  );
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );

  const { userDetail, creditLimits, imageAttributes, theme, gptModel } =
    useSelector((state) => state.authReducer);

  // target DOM using ref
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatFooterRef = useRef<ChatFooterMethods>(null);

  // state management using useState hook
  const [creditLimitModal, setCreditLimitModal] = useState<boolean>(false);
  const toggleCreditLimitModal = () =>
    setCreditLimitModal((prev) => {
      if (prev) {
        return false;
      } else {
        ReactGA.event({
          action: "WebsiteCreditCompletedPopupShown",
          category: "CreditCompletedPopupShown",
          label: "CreditCompletedPopupShown",
        });
        return true;
      }
    });
  const onConfirmCreditModal = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);
  const [getMessagesLoading, setGetMessagesLoading] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const [isAnswerComplete, setIsAnswerComplete] = useState<boolean>(true);
  const [isAllChunksReceived, setIsAllChunksReceived] = useState(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [IGLoading, setIGLoading] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });
  const [drag, setIsDrag] = useState<boolean>(false);
  const [isMainScreenOpen, setIsMainScreenOpen] = useState<boolean>(true);
  const [docuemntModel, setDocumentModel] = useState<boolean>(true);
  const toggleDocumentModel = () => setDocumentModel((prev) => !prev);
  const [selectedMessages, setSelectedMessages] = useState<any[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<number | undefined>();
  const [loadingShareChat, setLoadingShareChat] = useState(false);
  const [messageHeight, setMessageHeight] = useState(true);
  const [chatItem, setChatItem] = useState<IChat>();
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [showError, setShowErr] = useState<boolean>(false);

  const [credit, setCredit] = useState<number>(0);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<IUploadFile[]>([]);
  const [fileS3Link, setFileS3Link] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
  const [messageId, setMessageId] = useState<string>("");
  const [changeModel, setChangeModel] = useState<boolean>(false);

  // Abort Generate Related Question 
  const abortControllerRef = useRef<AbortController | null>(null);

  // STATE FOR SHARING CHAT
  const [share, setShare] = useState<boolean>(false);
  const toggleShareChat = () => setShare((prev) => !prev);

  // SEARCH CHAT HISTORY STATE
  const [searchQuery, setSearchQuery] = useState<string>("");

  // State for chat Seeting
  const [chatSettings, setChatSetting] = useState<boolean>(false);
  const [loadingSetting, setLoadingSetting] = useState<boolean>(false);
  const [settings, setSettings] = useState({
    real_time_results: false,
    related_questions: true,
    send_message_with_enter: true,
  });
  const [selectedSubOptions, setSelectedSubOptions] = useState<SelectedSubOptions>({});

  useEffect(() => {
    ErrorMessages(errorMessage, setErrorModal);
  }, [errorMessage]);

  const handleContainerScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } =
        chatContainerRef.current;

      setShowScrollToBottom(
        Math.round(scrollTop + clientHeight) < Math.round(scrollHeight)
      );
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener(
        "scroll",
        handleContainerScroll
      );
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener(
          "scroll",
          handleContainerScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    if (newMessages.length > 0) setIsMainScreenOpen(false);
  }, [newMessages]);

  useEffect(() => handleContainerScroll(), [messages, newMessages]);

  const scrollToBottom = ({ behavior }: ScrollOptions) => {
    if (chatContainerRef.current) {
      const { scrollHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTo({
        top: scrollHeight,
        behavior: behavior,
      });
    }
  };

  useEffect(() => () => startNewChat(), []);

  const clearMessage = () => {
    if (chatFooterRef.current) chatFooterRef.current.resetMessage();
  };

  const onChangeChat = () => {
    clearMessage();
    setSendMessageLoading(false);
    setIsAnswerComplete(true);
    setIsAllChunksReceived(false);

    if (isGenerating) {
      setDone(true);
      onStopGeneratingResponse();
    }

    setIsGenerating(false);

    if (chatFooterRef.current) chatFooterRef.current.onTextareaFocus();
  };

  const handleSelectAllChats = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (messages || newMessages) {
      const checked: boolean = event.target.checked;
      let selectedMessagesIds: (string | number)[] = [];
      const URL = pathname.includes("chat/new");
      setMessageHeight(false);

      if (checked) {
        {
          messages.length === 0 || URL ? (
            <>
              {
                (selectedMessagesIds = newMessages.map(
                  (message) => message.id! || message.content!
                ))
              }
              {setSelectedChatId(newMessages[0].chat_id)}
            </>
          ) : (
            <>
              {
                (selectedMessagesIds = messages.map(
                  (message) => message.id! || message.content!
                ))
              }
              {setSelectedChatId(messages[0].chat_id)}
            </>
          );
        }
      }
      setSelectedMessages(selectedMessagesIds);
    }
  };

  const getReaminingCredits = (): number => {
    // const adminCredits = userDetail?.user.activeSubscription.credits ?? 0;
    // if (userDetail?.user.team.role === "admin") {
    //   return adminCredits;
    // } else {
    //   const dailyCredits = creditLimits.daily_limit - creditLimits.used_today;
    //   if (adminCredits >= dailyCredits) {
    //     return dailyCredits;
    //   } else {
    //     setErrorModal({ message: "admin.credit.warning.message", show: true });
    //     return adminCredits;
    //   }
    // }
    const adminCredits = userDetail?.user.activeSubscription.credits ?? 0;
    if (userDetail?.user.team.role === "admin") {
      return adminCredits;
    } else {
      const dailyCredits = creditLimits.daily_limit - creditLimits.used_today;
      // return dailyCredits;
      return dailyCredits > 0 ? dailyCredits : 0;
    }
  };

  const getMessageAgainstKey = ({
    messagesArray,
    key,
  }: IGetMessageAgainstKey): IMessage => {
    const cleanedNewMessages = messagesArray.filter((message) => {
      return message !== undefined;
    });
    return (cleanedNewMessages as IMessage[])?.find(
      (msg) => msg[key as keyof IMessage]
    ) as IMessage;
  };

  const getLastMessage = (): IMessage => {
    if (chatId) {
      return messages[messages.length - 1];
    } else {
      return newMessages[newMessages.length - 1];
    }
  };

  const clearPrivateChat = (chatIds: number[]) => {
    removeMultipleChat(chatIds);
    sessionStorage.removeItem('PrivateChatId');
  };

  const onGetChatHistory = useCallback(() => {

    const history = pathname.includes("/chat/history");
    const previousPrivateChat = JSON.parse(localStorage.getItem('previousPrivateChat') || 'false');
    const historyPrivateChatId = Number(localStorage.getItem('PrivatehistoryChatID'));
    const PrivateChatId = Number(sessionStorage.getItem("PrivateChatId"));
    const currentPath = window.location.pathname;
    const pathSegments = currentPath.split('/');
    const chatIdFromUrl = pathSegments[pathSegments.length - 1];

    if (previousPrivateChat && PrivateChatId && !history) {
      clearPrivateChat([PrivateChatId]);
      return;
    }

    if ((previousPrivateChat && ((PrivateChatId || historyPrivateChatId) === Number(chatIdFromUrl)) && !PrivateChat && history)) {
      if (PrivateChatId === Number(chatIdFromUrl)) removeMultipleChat([PrivateChatId]);
      push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
      localStorage.removeItem('previousPrivateChat');
      sessionStorage.removeItem('PrivateChatId');
      localStorage.removeItem("historyPrivateChatId");
      return;
    }

    if (chatId) {
      setGetMessagesLoading(true);
      if (previousPrivateChat && PrivateChatId) clearPrivateChat([PrivateChatId]);
      getChatHistory(JSON.parse(chatId))
        .then((resp) => {
          const chat = resp as IChat;
          setChatItem(chat);
          setTimeout(() => setGetMessagesLoading(false), 310);
          setTimeout(() => {
            scrollToBottom({ behavior: "auto" });
            handleContainerScroll();
          }, 350);
        })
        .catch((err: any) => {
          setGetMessagesLoading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
          if (err?.data?.message === "Chat not found")
            push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
        });
    }

  }, [chatId, triggerNotification, setGetMessagesLoading]);

  const onBeforeUnload = () => {
    setDone(true);
    onStopGeneratingResponse();
  };

  useEffect(() => {
    if (!isOnline) onChangeChat();
  }, [isOnline]);

  useEffect(() => {
    if (chatId) {
      let chatItem = chatHistory.find((chat) => chat.id === Number(chatId));
      if (chatItem && chatModels?.length > 0) {
        if (!chatModel?.type?.includes(chatItem?.chat_type as EChatType)) {
          setChatModel(
            chatModels?.filter((item) =>
              item?.type?.includes(chatItem?.chat_type as EChatType)
            )?.[0]
          );
        }
      }
    }
  }, [chatId, chatHistory, chatModel, chatModels]);

  useBeforeUnload(() => {
    if (isGenerating) {
      onBeforeUnload();
      return true;
    }
    return false;
  });

  // useEffect(() => {
  //   return () => {
  //     if (isGenerating) onBeforeUnload();
  //   };
  // }, [isGenerating]);

  useEffect(() => onGetChatHistory(), [onGetChatHistory]);

  useEffectOnce(() => {
    if (userDetail?.token) {
      setLoadingSetting(true);
      getChatSettings()
        .then((res) => {
          setLoadingSetting(false);
          const chatSettings = res as SettingsBase;

          const options = getDropdownOptions(chatSettings?.bot_settings_allowed); 
          setDropDownOptions(options);
          setRememberSetting(chatSettings?.chat_settings?.customized_response || false);

          setSettings({
            real_time_results: chatSettings?.chat_settings?.real_time_results || false,
            related_questions: chatSettings?.chat_settings?.related_questions || false,
            send_message_with_enter:
              chatSettings?.chat_settings?.send_message_with_enter || false,
          });
          setSelectedSubOptions({
            outputFormats: chatSettings?.bot_settings?.output_format || 'default',
            tones: chatSettings?.bot_settings?.tone || 'default',
            writingStyles: chatSettings?.bot_settings?.style_of_writing || 'default',
            responseLengths: chatSettings?.bot_settings?.length || 'default',
            language: chatSettings?.bot_settings?.language || 'default',
          });
        })
        .catch((err) => {
          setLoadingSetting(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
      getActivePlan().catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
    }
  });

  useEffect(() => {
    if (typeof done === "boolean" && done === false) {
      setIsGenerating(true);
    }
    if (done) {
      setDone(null);
      setIsAllChunksReceived(true);
      setSendMessageLoading(false);
      setIsGenerating(false);
    }
  }, [done]);

  const onNewTextChat = (
    question: string,
    chatModels: IChatModel = {} as IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => {
    const cleanedNewMessages = newMessages.filter((message) => {
      return message !== undefined;
    });
    const messageWithQuestions = getMessageAgainstKey({
      messagesArray: cleanedNewMessages,
      key: "related_questions",
    });
    if (messageWithQuestions?.id) {
      removeRelatedQuestions({
        messageId: messageWithQuestions?.id,
        messageViewType: "new",
      });
    }

    const initialImages =
      images &&
      images.map((url, index) => ({
        id: index + 1,
        model_type: "",
        model_id: 1,
        name: null,
        path: url,
        size: "",
        created_at: "",
        updated_at: "",
      }));

    const files =
      filePath &&
      filePath.map((url, index) => ({
        id: index + 1,
        model_id: 1,
        name: "",
        path: url,
        created_at: "",
        updated_at: "",
      }));

    askNewChatQuestion({
      content: question.trim(),
      type: "user",
      images: initialImages ? initialImages : [],
      files: files ?? undefined,
    });
    answerNewChatQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    const messageWithChatId = getMessageAgainstKey({
      messagesArray: newMessages,
      key: "chat_id",
    });
    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    if (newMessages[0]?.images?.length > 0 || (images && images?.length > 0)) {
      localStorage.setItem('GptModel', JSON.stringify(gptModel));
      localStorage.setItem('imageChatGptModel', JSON.stringify(gptModel));
    }
    else if (chatModel?.type?.includes("document") && ((filePath && filePath.length > 0) || (newMessages[0]?.files && newMessages[0]?.files.length > 0))) {
      localStorage.setItem('documentChatGptModel', JSON.stringify(gptModel));
      localStorage.setItem('GptModel', JSON.stringify(gptModel));
    }

    abortControllerRef.current = new AbortController();
    setUploadingFiles([]);
    setFileS3Link([]);
    startStream({
      message: question,
      newChatBoolean: messageWithChatId?.chat_id ? false : true,
      chatId: messageWithChatId?.chat_id || null,
      model:
        chatModels && chatModels.model
          ? (chatModels?.model as string)
          : (chatModel?.model as string),
      chatType:
        chatModel?.type?.includes("document") &&
          ((filePath && filePath.length > 0) ||
            (newMessages[0]?.files && newMessages[0]?.files.length > 0))
          ? "document"
          : newMessages[0]?.images.length > 0 || (images && images?.length > 0)
            ? "image_chat"
            : "text",
      messageViewType: "new",
      images: images,
      file_path: filePath,
      regenerate: regenerate ? regenerate : false,
      isPrivate: PrivateChat,
    })
      .then((res: any) => {
        if (userDetail?.user?.activeSubscription?.name === 'Free') getActivePlan();
        setIsFileUploading(false);
        setOpenHistory(false);

        if (!res || !res.chatId) return;
        if (chatSetting?.related_questions) {
          generateRelatedQuestions({
            chatId: res.chatId,
            messageViewType: "new",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }
      })
      .catch((error: any) => {
        if (error.message === "Failed to fetch") {
          answerNewChatQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
        setOpenHistory(false);
        setSendMessageLoading(false);
        setIsAnswerComplete(true);
        setIsAllChunksReceived(false);
        setIsGenerating(false);
        setIsFileUploading(false);
        setDone(true);
        setOpenHistory(false);
      });
  };

  const onRegularTextChat = (
    question: string,
    chatModels: IChatModel = {} as IChatModel,
    regenerate?: boolean | undefined,
    images?: string[],
    filePath?: string[]
  ) => {
    const messageWithQuestions = getMessageAgainstKey({
      messagesArray: messages,
      key: "related_questions",
    });
    if (messageWithQuestions?.id) {
      removeRelatedQuestions({
        messageId: messageWithQuestions?.id as number,
        messageViewType: "history",
      });
    }

    const initialImages =
      images &&
      images.map((url, index) => ({
        id: index + 1,
        model_type: "",
        model_id: 1,
        name: null,
        path: url,
        size: "",
        created_at: "",
        updated_at: "",
      }));

    const files =
      filePath &&
      filePath.map((url, index) => ({
        id: index + 1,
        model_id: 1,
        name: "",
        path: url,
        created_at: "",
        updated_at: "",
      }));

    askRegularQuestion({
      type: "user",
      content: question?.trim(),
      chat_id: Number(chatId),
      images: initialImages ? initialImages : [],
      files: files ?? [],
    });
    answerRegularQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    const messageWithFile = getMessageAgainstKey({
      messagesArray: messages,
      key: "file",
    });
    abortControllerRef.current = new AbortController();
    setUploadingFiles([]);
    setFileS3Link([]);
    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    startStream({
      message: question,
      newChatBoolean: false,
      chatId: Number(chatId),
      model:
        chatModels && chatModels.model
          ? (chatModels?.model as string)
          : (chatModel?.model as string),
      chatType:
        chatModel?.type?.includes("document") &&
          ((messageWithFile?.files && messageWithFile?.files[0]?.path) ||
            (newMessages[0]?.files && newMessages[0]?.files.length > 0) ||
            (messages[0]?.files && messages[0]?.files?.length > 0) ||
            (filePath && filePath?.length > 0))
          ? "document"
          : (messages[0]?.images && messages[0]?.images.length > 0) ||
            (images && images.length > 0)
            ? "image_chat"
            : "text",
      messageViewType: "history",
      file_path: filePath,
      images: images,
      regenerate: regenerate ? regenerate : false,
      isPrivate: PrivateChat,
    })
      .then(() => {
        setIsFileUploading(false);
        if (chatSetting?.related_questions) {
          generateRelatedQuestions({
            chatId: Number(chatId),
            messageViewType: "history",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          answerRegularQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
        setSendMessageLoading(false);
        setIsAnswerComplete(true);
        setIsAllChunksReceived(false);
        setIsGenerating(false);
        setIsFileUploading(false);
        setDone(true);
        setOpenHistory(false);
      });
  };

  const onNewIGChat = (question: string) => {
    askNewChatQuestion({
      content: question.trim(),
      type: "user",
      images: [],
    });
    answerNewChatQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    const messageWithChatId = getMessageAgainstKey({
      messagesArray: newMessages,
      key: "chat_id",
    });

    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    generateImage({
      message: question.trim(),
      chatId: messageWithChatId?.chat_id ?? null,
      newChatBoolean: messageWithChatId?.chat_id ? false : true,
      model: chatModel?.model as string,
      chatType: "image",
      imageCount: Number(imageAttributes?.count),
      size: imageAttributes?.size?.value as string,
      messageViewType: "new",
      isPrivate: PrivateChat,
    })
      .then((res: any) => {
        setIGLoading(false);
        answerNewChatQuestion(res?.assistant_message);
        updateNewRegularQuestion(res?.user_message);
        if (userDetail?.user.team.role === "admin") {
          updateCredits(res?.credits);
        } else {
          updateCreditLimit({
            daily_limit: res?.daily_limit,
            used_today: res?.used_today,
          });
        }
        if (!messageWithChatId?.chat_id && res?.chat?.is_private === false) {
          setTimeout(
            () =>
              getNewChatTopic(res?.chat?.id)
                .then((resp: any) => {
                  const newChatHistory = [resp, ...chatHistoryPagination];
                  setChatHistory(newChatHistory);
                })
                .catch((err: any) => console.error(err)),
            5000
          );
        }
      })
      .catch((error: any) => {
        setIGLoading(false);
        if (error?.data?.message === "Network Error") {
          answerNewChatQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
      });
  };

  const onRegularIGChat = (question: string) => {
    askRegularQuestion({
      type: "user",
      content: question.trim(),
      chat_id: Number(chatId),
      images: [],
    });
    answerRegularQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    generateImage({
      message: question.trim(),
      chatId: Number(chatId) ?? null,
      newChatBoolean: chatId ? false : true,
      model: chatModel?.model as string,
      chatType: "image",
      imageCount: Number(imageAttributes?.count),
      size: imageAttributes?.size?.value as string,
      messageViewType: "history",
      isPrivate: PrivateChat,
    })
      .then((res: any) => {
        setIGLoading(false);
        answerRegularQuestion(res?.assistant_message);
        updatedRegularQuestion(res?.user_message);
        if (userDetail?.user.team.role === "admin") {
          updateCredits(res?.credits);
        } else {
          updateCreditLimit({
            daily_limit: res?.daily_limit,
            used_today: res?.used_today,
          });
        }
      })
      .catch((error: any) => {
        setIGLoading(false);
        if (error?.data?.message === "Network Error") {
          answerRegularQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
      });
  };

  const onSendMessage = (
    question: string,
    chatModels: IChatModel = {} as IChatModel,
    regenerate?: boolean | undefined,
    images?: string[],
    filePath?: string[]
  ) => {
    if (isFileUploading) {
      return;
    }
    if (filePath && filePath.length > 0) {
      if (documentMaxCountReached()) {
        setMessageId!("documentChat.plan.max_count");
        setSelectedFile!(null);
        setUploadingFiles!([]);
        setIsMainScreenOpen!(true);
        return;
      }
    }
    setShare(false);
    setMessageHeight(true);
    setChatItem(undefined);

    if (gptModel?.type.includes("text")) {
      const credits =
        Number(gptModel?.credits_per_message) +
        (images ? images?.length : 0) +
        (settings.real_time_results ? 1 : 0);
      setCredit(credits);
    }

    if (
      (question?.trim() ||
        (images && images?.length > 0) ||
        (filePath && filePath.length > 0)) &&
      !sendMessageLoading &&
      isAnswerComplete &&
      !IGLoading
    ) {
      if (
        Number(chatModel?.credits_per_message) >
        (userDetail?.user as IUser).activeSubscription.credits ||
        getReaminingCredits() <= 0
      ) {
        toggleCreditLimitModal();
      } else {
        ReactGA.event({
          action: "WebsiteChatScreenSendMessageBtn_action",
          category: (userDetail?.user as IUser).activeSubscription.name,
          label: new Date().toISOString(),
          value: 4,
        });
        ReactGA.event({
          action: `Website-messageSent${chatModel?.name
            .replace(/-/g, "_")
            .replace(/\./g, "")}`,
          category: "MessageSentModel",
          label: chatModel?.name?.replace(/-/g, "_").replace(/\./g, ""),
        });
        if (
          chatModel?.type?.includes("image") &&
          !chatModel?.type?.includes("image_chat")
        ) {
          setIGLoading(true);
          if (chatId) onRegularIGChat(question);
          else onNewIGChat(question);
        } else {
          abortControllerRef?.current?.abort();
          setSendMessageLoading(true);
          setIsAnswerComplete(false);
          setIsAllChunksReceived(false);
          if (chatId)
            onRegularTextChat(
              question,
              chatModels,
              regenerate,
              images,
              filePath
            );
          else
            onNewTextChat(question, chatModels, regenerate, images, filePath);
        }
        clearMessage();
        if (chatFooterRef.current) chatFooterRef.current.onTextareaFocus();
      }
    }
  };

  const onSubmit = ({
    event,
    message,
  }: {
    event: React.FormEvent<EventTarget | HTMLFormElement>;
    message: string;
  }) => {
    event.preventDefault();
    onSendMessage(message);
  };

  const onEnter = ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      onSubmit({ event: event, message });
    }
  };

  const onStopGeneratingResponse = () => {
    if (chatFooterRef.current) chatFooterRef.current.onTextareaFocus();
    const messageWithChatId = getMessageAgainstKey({
      messagesArray: newMessages,
      key: "chat_id",
    });
    abortControllerRef.current = new AbortController();

    const _chatId = chatId ? Number(chatId) : messageWithChatId?.chat_id;
    const _messageId = getLastMessage()?.id;
    if (!_chatId || !_messageId || !isGenerating) return;
    setIsAnswerComplete(true);
    setIsGenerating(false);
    setDone(true);
    setIsAnswerComplete(true);
    setIsAllChunksReceived(false);
    setIsFileUploading(false);
    setOpenHistory(false);
    controllerRef?.current?.abort();
    stopGeneratingResponse({
      chatId: chatId ? Number(chatId) : messageWithChatId?.chat_id,
      messageId: getLastMessage()?.id,
    })
      .then(() => {
        setIsAnswerComplete(true);
        setIsAllChunksReceived(true);
        setIsFileUploading(false);

        if (chatId && chatSetting?.related_questions && !abortControllerRef?.current?.signal?.aborted) {
          generateRelatedQuestions({
            chatId: Number(chatId),
            messageViewType: "history",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }
        if (messageWithChatId?.chat_id && chatSetting?.related_questions && !abortControllerRef?.current?.signal?.aborted
        ) {
          generateRelatedQuestions({
            chatId: messageWithChatId?.chat_id,
            messageViewType: "new",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }

        if (!chatId && newMessages.length < 3 && messageWithChatId?.chat_id && !PrivateChat) {
          setTimeout(() => {
            getNewChatTopic(_chatId)
              .then((resp: any) => {
                const newChatHistory = [resp, ...chatHistoryPagination];
                setChatHistory(newChatHistory);
              })
              .catch((err: any) => console.error(err));
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onGetUserMessage = (messageIndex: number) => {
    let lastQuestion = "";
    let messageImages: string[] = [];
    let docChat: string[] = [];
    if (chatId) {
      lastQuestion =
        messages[messageIndex]?.type === "user"
          ? (messages[messageIndex]?.content as string)
          : "";
      messageImages =
        messages[messageIndex]?.type === "user"
          ? (messages[messageIndex]?.images || []).map(
            (image: IImage) => image.path
          )
          : [];
      docChat =
        messages[messageIndex]?.type === "user"
          ? (messages[messageIndex]?.files || [])
            .map((file: IFile) => file.path)
            .filter((path): path is string => path !== null)
          : [];
    }
    if (!chatId) {
      lastQuestion =
        newMessages[messageIndex]?.type === "user"
          ? (newMessages[messageIndex]?.content as string)
          : "";
      messageImages =
        newMessages[messageIndex]?.type === "user"
          ? (newMessages[messageIndex]?.images || []).map(
            (image: IImage) => image.path
          )
          : [];
      docChat =
        newMessages[messageIndex]?.type === "user"
          ? (newMessages[messageIndex]?.files || [])
            .map((file: IFile) => file.path)
            .filter((path): path is string => path !== null)
          : [];
    }

    return {
      lastQuestion: lastQuestion,
      images: messageImages,
      filePath: docChat,
    };
  };

  const documentMaxCountReached = (): boolean => {
    const max_count = currentPlan?.attributes?.max_document_chats as number;
    return (
      (userDetail?.user.activeSubscription.document_chat_count as number) >=
      max_count
    );
  };

  const onRegenerate = (
    messageIndex: number,
    model: IChatModel = {} as IChatModel
  ) => {
    setShare(false);
    const regenerate = true;
    let userMessage = onGetUserMessage(messageIndex);
    if (
      userMessage.lastQuestion ||
      userMessage.images.length > 0 ||
      userMessage.filePath.length > 0
    ) {
      onSendMessage(
        userMessage.lastQuestion,
        model,
        regenerate,
        userMessage.images,
        userMessage.filePath
      );
    }
  };

  const updateChatModel = useCallback(
    (modelType: EChatType) => {
      let index = chatModels.findIndex((model) =>
        model.type.includes(modelType) && model.attributes?.default === true
      );

      if (index >= 0) {
        setIsMainScreenOpen(false);
        chatFooterRef?.current?.onTextareaFocus?.();
        if (
          (_.isEqual(gptModel?.type, ["document", "text"]) ||
            (gptModel?.type.includes("text") &&
              gptModel?.type.includes("document"))) &&
          modelType === "document"
        ) {
        } else {
          if ((modelType === 'image')) {
            const imageGPTModel = localStorage.getItem('imageGptModel');
            const selectedModel = imageGPTModel ? JSON.parse(imageGPTModel) : chatModels[index];
            setChatModel(selectedModel);
            if (!imageGPTModel)
              localStorage.setItem('imageGptModel', JSON.stringify(chatModels[index]))
          }
          else {
            setChangeModel(true);
            const imageGPTModel = localStorage.getItem('documentChatGptModel');
            const selectedModel = imageGPTModel ? JSON.parse(imageGPTModel) : chatModels[index];
            localStorage.setItem('GptModel', imageGPTModel || JSON.stringify(chatModels[index]));
            setChatModel(selectedModel);
          }
        }
        if (modelType === "document" && newMessages.length > 0) {
          startNewChat();
        }
      }
    },
    [chatModels, newMessages, gptModel, chatFooterRef?.current?.onTextareaFocus]
  );


  // Use useMemo to cache the result of isDisabled calculation
  const isAllowUploadFile = useMemo(() => {
    // Check if gptModel type includes ChatType.image
    if (gptModel?.type.includes(ChatType.image)) {
      return false;
    }
    const history = pathname.includes("/chat/history");

    // Check if it's history and chatItem type is ChatType.image or ChatType.text
    if ((chatItem && history) || (history && ((!messages[0]?.images && !messages[0]?.files)))) {
      if (
        chatItem?.chat_type === ChatType.image ||
        chatItem?.chat_type === ChatType.text || (!messages[0]?.images && !messages[0]?.files)
      ) {
        return false;
      }
    }
    // Check if newMessages have content (images or files)
    // new Chat
    if (newMessages.length > 0) {
      const firstMessage = newMessages[0];
      if (
        (firstMessage?.images?.length === 0 &&
          !firstMessage?.files) ||
        (firstMessage?.files && firstMessage?.files?.length === 0 && firstMessage?.images?.length === 0)
      ) {
        return false;
      }
    }

    return true;
  }, [gptModel, chatItem, newMessages.length, uploadingFiles, messages]);

  const onConfirm = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
  const onCancel = () => setMessageId("");

  const routes: IRoute[] = useMemo(() => {
    return [
      {
        path: `${ChatRoute.New}`,
        component: (
          <NewChat
            isMainScreenOpen={isMainScreenOpen}
            isAllChunksReceived={isAllChunksReceived}
            newMessages={newMessages}
            isGenerating={isGenerating}
            isFileUploading={isFileUploading}
            IGLoading={IGLoading}
            setIsAnswerComplete={setIsAnswerComplete}
            setIsGenerating={setIsGenerating}
            onSendMessage={onSendMessage}
            onRegenerate={onRegenerate}
            docuemntModel={docuemntModel}
            toggleDocumentModel={toggleDocumentModel}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            share={share}
            toggleShareChat={toggleShareChat}
            setSelectedChatId={setSelectedChatId}
            messageHeight={messageHeight}
            setMessageHeight={setMessageHeight}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            updateChatModel={updateChatModel}
            drag={drag}
            setSelectedFile={setSelectedFile}
            setIsMainScreenOpen={setIsMainScreenOpen}
            setIsDrag={setIsDrag}
            setOpenHistory={setOpenHistory}
            setUploadingFiles={setUploadingFiles}
            setFileS3Link={setFileS3Link}
            setIsFileUploading={setIsFileUploading}
            uploadingFiles={uploadingFiles}
            changeModel={changeModel}
            setChangeModel={setChangeModel}
          />
        ),
      },
      {
        path: `${ChatRoute.History}/:chatId`,
        component: (
          <ChatHistory
            isAllChunksReceived={isAllChunksReceived}
            isloading={getMessagesLoading}
            messages={messages}
            isGenerating={isGenerating}
            setIsAnswerComplete={setIsAnswerComplete}
            setIsGenerating={setIsGenerating}
            onSendMessage={onSendMessage}
            IGLoading={IGLoading}
            onRegenerate={onRegenerate}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            share={share}
            toggleShareChat={toggleShareChat}
            setSelectedChatId={setSelectedChatId}
            messageHeight={messageHeight}
            setMessageHeight={setMessageHeight}
            chatItem={chatItem}
            searchQuery={searchQuery}
          />
        ),
      },
    ];
  }, [
    isAllChunksReceived,
    newMessages,
    messages,
    getMessagesLoading,
    isGenerating,
    isFileUploading,
    IGLoading,
    isMainScreenOpen,
    onRegenerate,
    onSendMessage,
    setIsGenerating,
    setIsAnswerComplete,
    setIsFileUploading,
    setIsMainScreenOpen,
  ]);
  return (
    <MainView
      sendMessageLoading={sendMessageLoading}
      isAnswerComplete={isAnswerComplete}
      onChangeChat={onChangeChat}
      contentContainerRef={chatContainerRef}
      isMainScreenOpen={isMainScreenOpen}
      setIsMainScreenOpen={setIsMainScreenOpen}
      docuemntModel={docuemntModel}
      setChatHistory={setChatHistory}
      chatHistoryPagination={chatHistoryPagination}
      setShare={setShare}
      setSelectedMessages={setSelectedMessages}
      setChatItem={setChatItem}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      setChatSetting={setChatSetting}
      updateChatModel={updateChatModel}
      setIsDrag={setIsDrag}
      setSelectedFile={setSelectedFile}
      isFileUploading={isFileUploading}
      setOpenHistory={setOpenHistory}
      controllerRef={controllerRef}
      uploadingFiles={uploadingFiles}
      setUploadingFiles={setUploadingFiles}
      setFileS3Link={setFileS3Link}
      isAllowUploadFile={isAllowUploadFile}
      fileS3Link={fileS3Link}
    >
      <div
        className={classNames(styles.container, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
          [styles.isShare]: theme === "light" && includeRoute("/share-chat/"),
          [styles.content]:
            (includeRoute(ChatRoute.New) &&
              newMessages.length === 0 &&
              !isFileUploading) ||
            (getMessagesLoading && !isFileUploading) || uploadingFiles.length > 0,
        })}
      >
        {!window.location.pathname.includes("share-chat") && (
          <Routes>
            {routes.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={Component} />
            ))}
            <Route path="*" element={<Navigate to={ChatRoute.New} replace />} />
          </Routes>
        )}
        {window.location.pathname.includes("share-chat") && (
          <ShareChat
            loadingShareChat={loadingShareChat}
            setLoadingShareChat={setLoadingShareChat}
          />
        )}
      </div>

      <ChatFooter
        ref={chatFooterRef}
        onSubmit={onSubmit}
        getMessagesLoading={getMessagesLoading}
        onEnter={onEnter}
        isAnswerComplete={isAnswerComplete}
        isGenerating={isGenerating}
        onStopGeneratingResponse={onStopGeneratingResponse}
        isFileUploading={isFileUploading}
        showScrollToBottom={showScrollToBottom}
        scrollToBottom={scrollToBottom}
        share={share}
        selectedMessages={selectedMessages}
        toggleShareChat={toggleShareChat}
        handleSelectAllChats={handleSelectAllChats}
        selectedChatId={selectedChatId}
        loadingShareChat={loadingShareChat}
        setSelectedMessages={setSelectedMessages}
        settings={settings}
        updateChatModel={updateChatModel}
        setChatHistory={setChatHistory}
        chatHistoryPagination={chatHistoryPagination}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setIsMainScreenOpen={setIsMainScreenOpen}
        setIsFileUploading={setIsFileUploading}
        IGLoading={IGLoading}
        onSendMessage={onSendMessage}
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
        credit={credit}
        setCredit={setCredit}
        uploadingFiles={uploadingFiles}
        fileS3Link={fileS3Link}
        setUploadingFiles={setUploadingFiles}
        setFileS3Link={setFileS3Link}
        isAllowUploadFile={isAllowUploadFile}
        chatItem={chatItem}
        selectedSubOptions={selectedSubOptions}
        setSelectedSubOptions={setSelectedSubOptions}
        loadingSetting={loadingSetting}
      />
      {creditLimitModal && (
        <CreditLimitModal
          onCancel={toggleCreditLimitModal}
          onClose={toggleCreditLimitModal}
          onConfirm={onConfirmCreditModal}
        />
      )}
      {chatSettings && (
        <ChatSettingModal
          onClose={() => setChatSetting(false)}
          loadingSetting={loadingSetting}
          settings={settings}
          setSettings={setSettings}
        />
      )}
      {errorModal.show && (
        <ErrorModal
          uploadURL={showError || ErrorMessages.length > 0 ? true : false}
          message={errorModal.message}
          onClose={() => {
            setErrorModal({ message: "", show: false });
            setErrorMessage([]);
          }}
        />
      )}

      {drag && (newMessages.length > 0 || chatId) && (
        <div
          className={classNames(styles.documentModalContainer, {
            [styles.dragDocument]: drag,
          })}
        >
          <DocumentModal
            chatPage={true}
            setSelectedFile={setSelectedFile}
            setIsMainScreenOpen={setIsMainScreenOpen}
            setIsDrag={setIsDrag}
            setErrorModal={setErrorModal}
            setShowErr={setShowErr}
            setOpenHistory={setOpenHistory}
            setUploadingFiles={setUploadingFiles}
            setIsFileUploading={setIsFileUploading}
            setFileS3Link={setFileS3Link}
            setErrorMessage={setErrorMessage}
            uploadingFiles={uploadingFiles}
            setMessageId={setMessageId}
            chatItem={chatItem}
          />
        </div>
      )}
      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </MainView>
  );
});