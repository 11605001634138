import { Fragment, useEffect,  } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import classNames from "classnames";
import styles from "./Chat.module.scss";

import { AssistanceIcon } from "../chatHistory/components/userName";

import { resetChatModel, EChatType } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  updateChatModel?: (modelType: EChatType) => void;
  isFileUploading?: boolean;
  // isPrivateChat?:boolean;
}

export const Chat = ({ 
  // isFileUploading
  // isPrivateChat
}: IProps) => {
  const { theme, gptModel } = useSelector((state) => state.authReducer);
  const { PrivateChat} = useSelector(
    (state) => state.chatReducer
  );

  useEffect(() => {
    if (_.isEqual(gptModel?.type, ["text"])) {
    } else if (_.isEqual(gptModel?.type, ["document", "text"])) {
    } else resetChatModel();
  }, [gptModel]);
  
  return (
    <Fragment>
      {/* {!isFileUploading && ( */}
            {! PrivateChat && (
                <div className="h-[100%]">
                    <div
                        className={classNames(styles.newContentContainer, {
                            [styles.light]: theme === 'light',
                            [styles.dark]: theme === 'dark',
                        })}
                    >
                        <AssistanceIcon className={styles.logo} />
                        <h1 className={styles.h1}>
                            <FormattedMessage id="chatpage.new.heading" />
                        </h1>
                        <p className={styles.message}>
                            <FormattedMessage id="chatpage.new.subHeading" />
                        </p>
                    </div>
                </div>
            )}
      {/* )} */}
      { PrivateChat  && (
                <div className={styles.privateContainer}>
                    <AssistanceIcon className={styles.logo} />
                    <h1 className={styles.private_h1}>
                        <FormattedMessage id="PrivateChat.heading" />
                    </h1>
                    <p className={styles.private_message}>
                        <FormattedMessage id="PrivateChat.subHeading" />
                    </p>
                </div>
            )}

    </Fragment>
  );
};
