import React from "react";
import classNames from "classnames";
import { useIntl } from "react-intl";
import styles from "../../chatFooter.module.scss";
import { useSelector } from "redux/hooks";
import { SelectedSubOptions } from "pages/ChatPage/ChatPage";
import { DropdownOption } from "redux/reducers";

interface subOption {
  label: string;
  value: string;
}

interface ISubOptionProps {
  subOptions: subOption[];
  selectedOption: string;
  optionkey: string;
  selectedSubOptions: SelectedSubOptions;
  onClick: (
    mainOptionValue: string,
    key: string,
    subOptionValue: string
  ) => void;
  dropdownOptions: DropdownOption[];
}

const SubOption: React.FC<ISubOptionProps> = ({
  subOptions,
  selectedOption,
  selectedSubOptions,
  optionkey,
  onClick,
  dropdownOptions,
}) => {

  const { formatMessage } = useIntl();
  const { theme } = useSelector((state) => state.authReducer);

  const subOptionBorderRadius = (options: subOption[], value: string) => {
    if (value === "outputFormats") return "10px 10px 0px 0px";
    if (value === options[options.length - 1]?.value)
      return "0px 0px 10px 10px";
    return "0px";
  };

  return (
    <div
      className={classNames(styles.outputformat, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
    >
      {subOptions?.map((subOption) => (
        <div
          key={subOption.value}
          className={classNames(styles.dropdownOption, {
            [styles.selected]:
              selectedSubOptions[selectedOption] === subOption.value,
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
          onClick={() => onClick(selectedOption, optionkey, subOption.value)}
          style={{
            borderRadius: subOptionBorderRadius(
              dropdownOptions.find((option) => option.value === selectedOption)
                ?.options || [],
              subOption.value
            ),
          }}
        >
          {selectedOption === "language" && subOption.value !== "default"
            ? subOption.label
            : formatMessage({
                id: `bot.setting.${selectedOption}.${subOption.value}`,
              })}
        </div>
      ))}
    </div>
  );
};

export default SubOption;
