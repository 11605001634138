import styles from "../../chatFooter.module.scss";
import { useState, useEffect, useRef, SetStateAction, Dispatch } from "react";
import { IBot, RoleBot } from "redux/actions";
import { useSelector } from "redux/hooks";
import classNames from "classnames";
import { SelectedSubOptions } from "pages/ChatPage/ChatPage";
import { Spinner } from "components";
import React from "react";
import SubOption from "./SubOptions";
import RememberBotSetting from "./RememberBotSetting";
import MainOption from "./MainOption";

interface IProps {
  onClose: () => void;
  selectedSubOptions: SelectedSubOptions;
  setSelectedSubOptions: Dispatch<SetStateAction<SelectedSubOptions>>;
  loadingSetting: boolean;
}

const BotSetting: React.FC<IProps> = ({
  onClose,
  selectedSubOptions,
  setSelectedSubOptions,
  loadingSetting,
}) => {
  const { PrivateChat, rollBotSetting } = useSelector(
    (state) => state.chatReducer
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { theme } = useSelector((state) => state.authReducer);
  const { dropdownOptions } = useSelector ((state) => state.chatReducer);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
  };

  const handleSubOptionClick = (
    mainOptionValue: string,
    key: string,
    subOptionValue: string
  ) => {
    setSelectedSubOptions((prev: SelectedSubOptions) => ({
      ...prev,
      [mainOptionValue]: subOptionValue,
    }));
    setSelectedOption(null);

    const updatedBot = {
      ...rollBotSetting,
      key: "bot_settings",
      [key]: subOptionValue,
    };

    const filteredBot = Object.fromEntries(
      Object.entries(updatedBot).filter(
        ([_, value]) => value !== "" && value !== "default"
      )
    ) as unknown as IBot;

    performOperation(filteredBot);
  };

  const performOperation = (data: IBot) => {
    RoleBot(data);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.dropdown, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
    >
      {loadingSetting ? (
        <div className="flex items-center justify-center py-3">
          {" "}
          <Spinner extraSmall />{" "}
        </div>
      ) : (
        <>

          {!PrivateChat && <RememberBotSetting />}

          {dropdownOptions!== null && dropdownOptions?.map((option) => {
            return (
              <React.Fragment key={option.value}>

                {/* Main-options dropdown */}

                <MainOption
                  option={option}
                  selectedOption={selectedOption}
                  onClick={() => handleOptionClick(option.value)}
                  selectedSubOptions={selectedSubOptions}
                />

                {/* Sub-options dropdown */}

                {selectedOption !== null &&
                  selectedOption === option.value &&
                  option.options && (
                    <>
                      <SubOption
                        subOptions={option.options}
                        optionkey={option.key}
                        selectedOption={selectedOption}
                        selectedSubOptions={selectedSubOptions}
                        onClick={handleSubOptionClick}
                        dropdownOptions={dropdownOptions}
                      />
                    </>
                  )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};
export default BotSetting;
