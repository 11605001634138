import classNames from "classnames";
import styles from "./ChatNav.module.scss";
import { Link } from "react-router-dom";
import CutsomButton from "components/Button";

import { InfoIcon, RightArrow, } from "./icons";
import { RoutePaths } from "pages/routePaths";
import styled from "styled-components";
import { useWindowSize } from "hooks/useWindowSize";
import { Credits } from "./components/Credits";

import { ChatRoute } from "pages/ChatPage";

import { useSelector } from "redux/hooks";

import useRouter from "hooks/useRouter";
import { FormattedMessage } from "react-intl";
import { SetStateAction, Dispatch } from "react";
import { Setting } from "./icons/setting";
import { IChat } from "redux/actions";

interface IProps {
  isMainScreenOpen?: boolean;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

const Button = styled(CutsomButton)`
  display: none;
  // margin-left: 20px;
  padding : 5px 40px ;
  @media screen and (min-width: 769px) {
    display: inline-flex;
  }
`;

export const ChatNav = ({
  isMainScreenOpen,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {

  const { includeRoute, push, pathname } = useRouter();
  const { width } = useWindowSize();
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { gptModel, theme, userDetail } = useSelector((state) => state.authReducer);
  const { shareMessages } = useSelector((state) => state.chatReducer);
  const isShare = window.location.pathname.includes("share-chat");
  const lang = userDetail?.user?.language? userDetail?.user?.language : "en";
  const storedGptModel = localStorage.getItem("GptModel");
  const parsedGptModel = storedGptModel ? JSON.parse(storedGptModel) : null;
  const history = pathname.includes("/chat/history");
  // Check if gptModel?.type includes "text"
  const modelToUse = (gptModel?.type?.includes("text") && !history && parsedGptModel) || gptModel;
  return (
    <>
      {isShare &&
        <div className={classNames(styles.LogoContainer)} >
          <Link to='/' className={styles.headerLogo} data-testid='share-header-logo'>
            <img src={`/logo-${theme}.svg`} alt='deftGPT logo' />
          </Link>
          {width > 1020 &&
            <div className={classNames(styles.multipleaiModel, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}>- Chat with multiple AI models</div>
          }
        </div>
      }
      {userDetail && userDetail.token &&
        <div className={styles.container}>
          {!isMainScreenOpen || isMainScreenOpen|| includeRoute(ChatRoute.History) || isShare ? (
            <button
              className={classNames(styles.modelButton, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
              data-testid='chatNav-model-btn'
              onClick={!isShare ? toggleChatModelsModel : () => { }}
            >
              <div className={styles.content}>
                {!isShare ?
                  <>
                    <div className={styles.modelIconContainer}>
                      <img className={ gptModel?.name === "flux-schnell" ? styles.image : ""}
                        src={
                          chatItem?.model?.image_url??
                          modelToUse?.image_url ??
                          // chatItem?.model?.image_url??
                          "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"
                        }
                      />
                    </div>
                    <div className={styles.model}>{modelToUse?.name}</div>
                  </>
                  :
                  <>{chatModels?.map(model => (
                    model.id === shareMessages?.model_id && (
                      <>
                        <div className={styles.modelIconContainer}>
                          <img
                            src={model?.image_url ?? "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"}
                          />

                        </div>
                        <div className={styles.model}>{model?.name}</div>
                      </>
                    )
                  ))}
                  </>
                }
                <RightArrow />
              </div>
            </button>
          ) : null}
          {isShare &&
            <span className="ml-4 opacity-80">
              <InfoIcon theme={theme} />
            </span>
          }
        </div>
      }

      {isShare ?
        <div className={styles.shareChatbtn}>
          <Button data-testid='share-chat-try-btn' className={styles.shareChatTryBtn}
            onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
          >
            <FormattedMessage id='share.chat.header' />
          </Button>
        </div> :
        <>
          <div className={styles.creditsContainer}>
            <div
              className={styles.settingIcon}
              onClick={() => setChatSetting!(true)}
              data-testid='chat-setting-icon'
            >
              <Setting />
            </div>
            <div>
              <Credits />
            </div>
          </div>
        </>
      }
    </>
  );
};
