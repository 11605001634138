import { memo, useState, Dispatch, SetStateAction, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import classNames from "classnames";
import styles from "./NewChat.module.scss";
import chatstyles from 'pages/ChatPage/pages/chat/Chat.module.scss';
import answerStyles from "../chatHistory/components/messageContainer/answerContainer.module.scss";
import messageStyles from "../chatHistory/components/message/message.module.scss";

import { AssistanceIcon } from "../chatHistory/components/userName";
import { DocumentModal } from "./components/DocumentModal";
import { ErrorModal } from "./components/ErrorModal";
import { ChatHistory, Chat } from "../";

import { ChatType, IMessage, EChatType, IChatModel } from "redux/actions";
import { useSelector } from "redux/hooks";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { ErrorMessages } from "utils/functions";
import { FileSizeLimitModal } from "pages/ChatPage/components/fileSizeLimitModal";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";


interface IProps {
  newMessages: IMessage[];
  isAllChunksReceived?: boolean;
  isGenerating?: boolean;
  isFileUploading: boolean;
  IGLoading: boolean;
  isMainScreenOpen?: boolean;
  setIsAnswerComplete: Dispatch<SetStateAction<boolean>>;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  onSendMessage?: (question: string, chatModels?: IChatModel, regenerate?: boolean, images?: string[]) => void;
  onRegenerate: (messageIndex: number, model?: IChatModel) => void;
  docuemntModel?: boolean;
  toggleDocumentModel?: () => void;
  toggleShareChat?: () => void;
  searchQuery?: string;
  setSearchQuery?: Dispatch<SetStateAction<string>>;
  selectedMessages?: any;
  setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
  share?: boolean;
  setSelectedChatId?: Dispatch<SetStateAction<number | undefined>>;
  messageHeight?: boolean;
  setMessageHeight?: Dispatch<SetStateAction<boolean>>;
  updateChatModel?: (modalType: EChatType) => void;
  drag?: boolean;
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setIsDrag: Dispatch<SetStateAction<boolean>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  changeModel?: boolean;
  setChangeModel?: Dispatch<SetStateAction<boolean>>;
}

export interface IFileErrorState {
  message: string;
  show: boolean;
}

export interface IErrorMessage {
  name: string;
  error: string;
}

export const NewChat = memo(
  ({
    isAllChunksReceived,
    newMessages,
    isGenerating,
    isFileUploading,
    IGLoading,
    isMainScreenOpen,
    setIsAnswerComplete,
    setIsGenerating,
    onSendMessage,
    onRegenerate,
    docuemntModel,
    // toggleDocumentModel,
    selectedMessages,
    setSelectedMessages,
    share,
    toggleShareChat,
    setSelectedChatId,
    messageHeight,
    setMessageHeight,
    updateChatModel,
    searchQuery,
    drag,
    setSelectedFile,
    setIsMainScreenOpen,
    setIsDrag,
    setOpenHistory,
    setUploadingFiles,
    setFileS3Link,
    setIsFileUploading,
    uploadingFiles,
    changeModel,
    setChangeModel,
  }: IProps) => {
    const { push } = useRouter();
    const { gptModel, theme } = useSelector((state) => state.authReducer);
    const { PrivateChat} = useSelector(
      (state) => state.chatReducer
    );

    const [errorModal, setErrorModal] = useState<IFileErrorState>({
      message: "",
      show: false,
    });
    const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
    const [messageId, setMessageId] = useState<string>("");

    useEffect(() => {
      ErrorMessages(errorMessage, setErrorModal)
    }, [errorMessage])

    const onConfirm = () =>
      push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
    const onCancel = () => setMessageId("");

    return (
      <div style={{ height: drag ? "auto" : "100%", overflow: errorModal.show || errorMessage.length > 0 ? 'hidden' : '' }}>
        {newMessages?.length > 0 ? (
          <ChatHistory
            isAllChunksReceived={isAllChunksReceived}
            messages={newMessages}
            isGenerating={isGenerating}
            setIsAnswerComplete={setIsAnswerComplete}
            setIsGenerating={setIsGenerating}
            onSendMessage={onSendMessage}
            IGLoading={IGLoading}
            onRegenerate={onRegenerate}
            searchQuery={searchQuery}
            selectedMessages={selectedMessages}
            setSelectedMessages={setSelectedMessages}
            share={share}
            toggleShareChat={toggleShareChat}
            setSelectedChatId={setSelectedChatId}
            messageHeight={messageHeight}
            setMessageHeight={setMessageHeight}
          />
        ) : (isMainScreenOpen)? (
          <Chat
            updateChatModel={updateChatModel}
            isFileUploading={isFileUploading}
          />
        ) : (!PrivateChat && gptModel?.type?.includes(ChatType.image)) ? (
          <div className={styles.infoContainer}>
            <div className={answerStyles.container}>
              <div className={answerStyles.leftContainer}>
                <AssistanceIcon />
                <div
                  className={answerStyles.messageContainer}
                  style={{ padding: "0 0 0 32px", marginTop: "-7px" }}
                >
                  <p
                    className={classNames(messageStyles.message, {
                      [messageStyles.light]: theme === "light",
                      [messageStyles.dark]: theme === "dark",
                    })}
                  >
                    <div
                      className={classNames(styles.imgfeature, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <FormattedMessage id="chatpage.image.feature1" />
                    </div>
                    <div
                      className={` mt-3 ${classNames(styles.imgfeature, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}`}
                    >
                      <FormattedMessage id="chatpage.image.feature2" />
                    </div>
                    <div
                      className={`mt-3 ${classNames(styles.imgfeature, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}`}
                    >
                      <FormattedMessage id="chatpage.image.feature3" />
                    </div>
                    {gptModel?.model === "stability.ai" && (
                      <div
                        className={`mt-3 ${classNames(styles.imgfeature, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        })}`}
                      >
                        <FormattedMessage id="chatpage.image.feature4" />
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ): (PrivateChat  && gptModel?.type?.includes(ChatType.image)) ? (
          <div className={chatstyles.privateContainer}>
              <AssistanceIcon className={chatstyles.logo} />
              <h1 className={chatstyles.private_h1}>
                  <FormattedMessage id="PrivateChat.heading" />
              </h1>
              <p className={chatstyles.private_message}>
                  <FormattedMessage id="PrivateChat.subHeading" />
              </p>
          </div>
        ): _.isEqual(gptModel?.type, ["text"]) ? (
          <div className={styles.infoContainer}>
            <div className={answerStyles.container}>
              <div className={answerStyles.leftContainer}>
                <AssistanceIcon />
                <div
                  className={answerStyles.messageContainer}
                  style={{ padding: "0 0 0 32px", marginTop: "-7px" }}
                >
                  <p
                    className={classNames(messageStyles.message, {
                      [messageStyles.light]: theme === "light",
                      [messageStyles.dark]: theme === "dark",
                    })}
                  >
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature1" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature2" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature3" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature4" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature5" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature6" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature7" />
                    </div>
                    <div className={styles.feature}>
                      <FormattedMessage id="chatpage.new.feature8" />
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {docuemntModel && (
              <div
                className={classNames(styles.documentModalContainer, {
                  [styles.dragDocument]: drag,
                })}
              >
                <DocumentModal
                  setSelectedFile={setSelectedFile}
                  setIsMainScreenOpen={setIsMainScreenOpen}
                  setIsDrag={setIsDrag}
                  setErrorModal={setErrorModal}
                  setOpenHistory={setOpenHistory}
                  setUploadingFiles={setUploadingFiles}
                  setErrorMessage={setErrorMessage}
                  setFileS3Link={setFileS3Link}
                  setIsFileUploading={setIsFileUploading}
                  uploadingFiles={uploadingFiles}
                  setMessageId={setMessageId}
                  changeModel={changeModel}
                  setChangeModel={setChangeModel}
                />
              </div>
            )}
          </>
        )}
        {errorModal.show && (
          <div className={styles.containerCenter}>
            <div
              className={classNames(styles.popupContainer, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <ErrorModal
                message={errorModal.message}
                uploadURL={true}
                onClose={() => {
                  setErrorModal({ message: "", show: false });
                  setIsMainScreenOpen!(true)
                  setErrorMessage([]);
                }}
              />
            </div>
          </div>
        )}

        {messageId && (
          <FileSizeLimitModal
            messageId={messageId}
            onCancel={onCancel}
            onClose={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </div>
    );
  }
);