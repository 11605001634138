import React from "react";
import styles from "../../chatFooter.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { FormattedMessage } from "react-intl";
import { ChatType, setImageAttribute } from "redux/actions";
import { CountDD } from "../CountDD";
import { SizeDD } from "../SizeDD";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { ImageCountOptions } from "utils/constants";

interface FilePreviewProps {
  getMessagesLoading: boolean | undefined;
}

export const ImageGenerationOptions: React.FC<FilePreviewProps> = ({
  getMessagesLoading,
}) => {
  const { theme, gptModel, imageAttributes } = useSelector(
    (state) => state.authReducer
  );
  const { isOpen } = useToggleSidebar();

  return (
    <>
      {
      // !getMessagesLoading &&
        gptModel?.type?.includes(ChatType.image) &&
        !gptModel?.type?.includes(ChatType.image_chat) && (
          <div
            className={classNames(styles.IGOptionsContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.isSidebarOpen]: isOpen,
              [styles.isbackdrop]: getMessagesLoading,
            })}
          >
            <div className={styles.IGOptions}>
              <div className={styles.option}>
                <div className={styles.label}>
                  <FormattedMessage id="imageGeneration.chatFooter.dropdown.label.imageCount" />
                </div>
                <CountDD
                  selectedOption={Number(imageAttributes?.count)}
                  options={ImageCountOptions}
                  maxCount={imageAttributes?.maxCount}
                  onSelect={(option) =>
                    setImageAttribute({
                      key: "count",
                      value: option.value,
                    })
                  }
                  dataTestId="chat-footer-count"
                />
              </div>

              <div className={styles.option}>
                <div className={styles.label}>
                  <FormattedMessage id="imageGeneration.chatFooter.dropdown.label.size" />
                </div>
                <SizeDD
                  selectedOption={imageAttributes?.size}
                  options={gptModel?.attributes?.sizes}
                  onSelect={(option) =>
                    setImageAttribute({
                      key: "size",
                      value: option,
                    })
                  }
                  dataTestId="chat-footer-size"
                />
              </div>
            </div>

            <div className={styles.imageCredits}>
              <FormattedMessage
                id={
                  Number(imageAttributes?.size?.credits_per_message) === 1
                    ? "chat.image.one.credit"
                    : "chat.image.credit.perQuery"
                }
                values={{
                  credits:
                    Number(imageAttributes?.count) *
                    Number(imageAttributes?.size?.credits_per_message),
                }}
              />
            </div>
          </div>
        )}
    </>
  );
};
