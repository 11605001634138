import _ from "lodash";
import { TYPES } from "../types";
import {
  BotSettings,
  DropdownOption,
  IChat,
  IChatSetting,
  IMessage,
  IPrompt,
  IRollBotSetting,
  IShareChat,
} from "../actions/chatInterface";
import { produce } from "immer";

export interface IChatReducerState {
  newMessages: IMessage[];
  chats: IChat[];
  messages: IMessage[];
  communityPrompts: IPrompt[];
  userPrompts: IPrompt[];
  shareMessages: IShareChat;
  chatSetting: IChatSetting;
  rollBotSetting: IRollBotSetting;
  RollBot: BotSettings;
  PrivateChat: boolean;
  RememberSetting: boolean;
  RememberType: string;
  dropdownOptions: DropdownOption[];
}

const state: IChatReducerState = {
  newMessages: [],
  chats: [],
  messages: [],
  communityPrompts: [],
  userPrompts: [],
  shareMessages: {
    id: 0,
    chat_id: 0,
    model_id: 0,
    token: "",
    chat_type: '',
    messages: [],
    created_at: "",
    updated_at: "",
  },
  chatSetting: {
    real_time_results: false,
    related_questions: false,
    send_message_with_enter: false,
    // customized_response: false,
  },
  rollBotSetting: {
    language: '',
    length: '',
    output_format: '',
    style_of_writing: '',
    tone: ''
  },
  RollBot: {
    writingStyles: [],
    languages: [],
    outputFormats: [],
    responseLengths: [],
    tones: [],
  },
  PrivateChat: false,
  RememberSetting: false,
  RememberType: "",
  dropdownOptions:
    [{
      key: "output_format",
      label: "Output Format",
      value: "outputFormats",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "tone",
      label: "Tone",
      value: "tones",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "style_of_writing",
      label: "Writing Style",
      value: "writingStyles",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "language",
      label: "Language",
      value: "language",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "length",
      label: "Length",
      value: "responseLengths",
      options: [{ label: 'Default', value: 'default' }],
    }],
};

export const chatReducer = (
  mState = { ...state },
  action: any
): IChatReducerState => {
  switch (action.type) {
    case TYPES.START_NEW_CHAT:
      return produce(mState, (dState) => {
        dState.newMessages = action.payload;
      });

    case TYPES.NEW_CHAT:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message) => {
          if (message?.isNew === true) {
            const { isNew, ...rest } = message;
            return { ...rest, isNew: false };
          } else {
            return message;
          }
        });
        dState.newMessages.push(action.payload);
        dState.messages = [];
      });

    case TYPES.ANSWER_NEW_CHAT:
      return produce(mState, (dState) => {
        if (
          !action.payload.chat_id &&
          !action.payload.content &&
          action.payload.images.length === 0
        ) {
          dState.newMessages = [...dState.newMessages, action.payload];
        } else if (action.payload.images.length > 0) {
          dState.newMessages[dState.newMessages.length - 1].id =
            action.payload.id;
          dState.newMessages[dState.newMessages.length - 1].chat_id =
            action.payload.chat_id;
          dState.newMessages[dState.newMessages.length - 1].images =
            action.payload.images;
        } else if (action.payload.chat_id && !action.payload.content) {
          dState.newMessages[dState.newMessages.length - 1].id =
            action.payload.id;
          dState.newMessages[dState.newMessages.length - 1].chat_id =
            action.payload.chat_id;
          dState.newMessages[
            dState.newMessages.length - 1
          ].regenerate_model_id = action.payload.regenerate_model_id;
        } else {
          dState.newMessages[dState.newMessages.length - 1].content +=
            action.payload.content;
        }
        dState.messages = [];
      });

    case TYPES.REGULAR_CHAT:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message) => {
          if (message.isNew === true) {
            const { isNew, ...rest } = message;
            return { ...rest, isNew: false };
          } else {
            return message;
          }
        });
        dState.messages.push(action.payload);
      });

    case TYPES.UPDATED_NEW_REGULAR_CHAT:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message) => {
          if (message.type === "user" && !message.id) {
            message = { ...message, ...action.payload };
          }
          return message;
        });
      });

    case TYPES.UPDATE_REGULAR_CHAT:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message) => {
          if (message.type === "user" && !message.id) {
            message = { ...message, ...action.payload };
          }
          return message;
        });
      });

    case TYPES.ANSWER_REGULAR_CHAT:
      return produce(mState, (dState) => {
        if (
          !action.payload.chat_id &&
          !action.payload.content &&
          action.payload.images.length === 0
        ) {
          dState.messages = [...dState.messages, action.payload];
        } else if (action.payload.chat_id && action.payload.images.length > 0) {
          dState.messages[dState.messages.length - 1].id = action.payload.id;
          dState.messages[dState.messages.length - 1].chat_id =
            action.payload.chat_id;
          dState.messages[dState.messages.length - 1].images =
            action.payload.images;
        } else if (action.payload.chat_id && !action.payload.content) {
          dState.messages[dState.messages.length - 1].id = action.payload.id;
          dState.messages[dState.messages.length - 1].chat_id =
            action.payload.chat_id;
          dState.messages[dState.messages.length - 1].regenerate_model_id =
            action.payload.regenerate_model_id;
        } else {
          dState.messages[dState.messages.length - 1].content +=
            action.payload.content;
        }
      });

    case TYPES.GENERATE_IMAGE_ERROR:
      return produce(mState, (dState) => {
        const { message, messageViewType } = action.payload;
        if (
          messageViewType === "new" &&
          dState.newMessages[dState.newMessages.length - 1]
        ) {
          dState.newMessages[dState.newMessages.length - 1].content = message;
        }
        if (
          messageViewType === "history" &&
          dState.messages[dState.messages.length - 1]
        ) {
          dState.messages[dState.messages.length - 1].content = message;
        }
      });

    case TYPES.UPSCALE_IMAGE:
      return produce(mState, (dState) => {
        const { messageViewType, message_id, image, upscaledImage } =
          action.payload;
        if (messageViewType === "new") {
          const index = dState.newMessages.findIndex(
            (message) => message.id === message_id
          );
          if (index >= 0) {
            const upscaledimageIndex = dState.newMessages[
              index
            ].images.findIndex((img) => img.id === upscaledImage.image_id);
            if (upscaledimageIndex >= 0) {
              dState.newMessages[index].images[
                upscaledimageIndex
              ].upscaled_images = [upscaledImage];
              dState.newMessages[index].images = [
                ...dState.newMessages[index].images,
                image,
              ];
            }
          }
        }
        if (messageViewType === "history") {
          const index = dState.messages.findIndex(
            (message) => message.id === message_id
          );
          if (index >= 0) {
            const upscaledimageIndex = dState.messages[index].images.findIndex(
              (img) => img.id === upscaledImage.image_id
            );
            if (upscaledimageIndex >= 0) {
              dState.messages[index].images[
                upscaledimageIndex
              ].upscaled_images = [upscaledImage];
              dState.messages[index].images = [
                ...dState.messages[index].images,
                image,
              ];
            }
          }
        }
      });

    case TYPES.GET_ALL_CHATS:
      return produce(mState, (dState) => {
        dState.chats = [...action.payload.reverse()];
      });

    case TYPES.DELETE_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.chats = dState.chats.filter(
          (chat) => chat.id !== action.payload.id
        );
      });

    case TYPES.DELETE_MULTIPLE_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.chats = dState.chats.filter(
          (chat) => chat.id !== action.payload.includes(chat.id)
        );

      });

    case TYPES.UPDATE_CHAT_HISTORY:
      return produce(mState, (dState) => {
        let index = dState.chats.findIndex(
          (chat) => chat.id === action.payload.id
        );
        if (index >= 0) dState.chats[index].topic = action.payload.topic;
      });

    case TYPES.GET_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.messages = [...action.payload];
        dState.newMessages = [];
      });

    case TYPES.ADD_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.chats.unshift(action.payload);
      });

    case TYPES.SAVE_FILE_PATH:
      return produce(mState, (dState) => {
        dState.chats = [action.payload.chatItem, ...dState.chats];
        dState.newMessages = action.payload.message;
      });

    case TYPES.REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message) => {
          if (message?.id === action.payload) {
            return _.omit(message, "related_questions");
          } else {
            return message;
          }
        });
      });

    case TYPES.REMOVE_RELATED_QUESTIONS_FROM_MESSAGES:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message) => {
          if (message.id === action.payload) {
            return _.omit(message, "related_questions");
          } else {
            return message;
          }
        });
      });

    case TYPES.REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES:
      return produce(mState, (dState) => {
        dState.newMessages.splice(-1);
      });

    case TYPES.REMOVE_LAST_MESSAGE_FROM_MESSAGES:
      return produce(mState, (dState) => {
        dState.messages.splice(-1);
      });

    case TYPES.GET_GENERATED_QUESTIONS_NEW:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message, index) => {
          if (dState.newMessages.length === index + 1) {
            return {
              ...message,
              related_questions: action.payload,
            };
          } else {
            return message;
          }
        });
      });

    case TYPES.GET_GENERATED_QUESTIONS_HISTORY:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message, index) => {
          if (dState.messages.length === index + 1) {
            return {
              ...message,
              related_questions: action.payload,
            };
          } else {
            return message;
          }
        });
      });

    case TYPES.GET_ALL_PROMPT:
      return produce(mState, (dState) => {
        dState.communityPrompts = [...action.payload];
      });

    case TYPES.GET_USER_PROMPT:
      return produce(mState, (dState) => {
        dState.userPrompts = [...action.payload];
      });

    case TYPES.DELETE_USER_PROMPT:
      return produce(mState, (dState) => {
        dState.userPrompts = dState.userPrompts.filter(
          (chat) => chat.id !== action.payload.id
        );
      })

    case TYPES.UPDATE_USER_PROMPT:
      return produce(mState, (dState) => {
        let index = dState.userPrompts.findIndex(
          (chat) => chat.id === action.payload.id
        );
        if (index >= 0) {
          dState.userPrompts[index].title = action.payload.data.title;
          dState.userPrompts[index].prompt = action.payload.data.prompt;
        }
      });

    case TYPES.GET_SHARE_CHAT:
      return produce(mState, (dState) => {
        dState.shareMessages = action.payload;
      });

    case TYPES.GET_CHAT_SETTING:
      return produce(mState, (dState) => {
        dState.chatSetting = action.payload;
      });

    case TYPES.GET_ROLL_BOT_SETTING:
      return produce(mState, (dstate) => {
        dstate.rollBotSetting = action.payload
      })

    case TYPES.GET_ROLL_BOT:
      return produce(mState, (dstate) => {
        dstate.RollBot = action.payload
      })

    case TYPES.SET_PRIVATE_CHAT:
      return produce(mState, (dState) => {
        dState.PrivateChat = action.payload;
      });

    case TYPES.SET_REMEMBER_SETTING:
      return produce(mState, (dState) => {
        dState.RememberSetting = action.payload;
      })

    case TYPES.SET_REMEMBER_Chat_Type:
      return produce(mState, (dState) => {
        dState.RememberType = action.payload;
      })

    case TYPES.SET_DROP_DOWN_OPTIONS: {
      return produce(mState, (dState) => {
        dState.dropdownOptions = action.payload;
      });
    }

    case TYPES.INIT_USER_DETAILS:
      return produce(mState, (dState) => {
        dState.newMessages = [];
        dState.chats = [];
        dState.messages = [];
        dState.userPrompts = [];
        dState.communityPrompts = [];
      });

    case TYPES.CLEAR_NEW_MESSAGES:
      return produce(mState, (dState) => {
        dState.newMessages = [];
      });

    default:
      return { ...mState };
  }
};