import { EThemeType } from "redux/reducers";

interface props{
  BotDropdown?:boolean,
  theme?: EThemeType,
}
export const RightArrow = ({BotDropdown,theme}:props) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9066 13.4719L9.33789 7.9031L10.5473 6.69373L17.3254 13.4719L10.5473 20.25L9.33789 19.0406L14.9066 13.4719Z"
        fill={theme==='light' && BotDropdown? "black": "white"}
      />
    </svg>
  );
};
