import {
  SetStateAction,
  Dispatch,
  useEffect,
  useState,
  DragEvent,
  memo,
  forwardRef,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./documentModal.module.scss";
import { Modal } from "components";

import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";
import { NewUploadIcon } from "../../icons/NewUploadIcon";
import { Chat } from "pages/ChatPage/pages/chat";
import {setChatModel, IChat} from "redux/actions";
import { IErrorMessage, IFileErrorState } from "../../NewChat";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { validateFile, validateImgFile } from "utils/fileService";
import { convertFileNamesToLowercase } from "utils/functions";

import { useFileUploader } from "utils/fileUploadS3";

interface IProps {
  setSelectedFile: Dispatch<SetStateAction<File[] | null>>;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  chatPage?: boolean;
  setIsDrag: Dispatch<SetStateAction<boolean>>;
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
  setShowErr?: Dispatch<SetStateAction<boolean>>;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setErrorMessage?: Dispatch<SetStateAction<IErrorMessage[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  setMessageId?: Dispatch<SetStateAction<string>>;
  changeModel?: boolean;
  setChangeModel?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

export interface DocumentModalMethods { }

export const DocumentModal = memo(
  forwardRef<DocumentModalMethods, IProps>(
    ({
      setSelectedFile,
      setIsMainScreenOpen,
      chatPage,
      setIsDrag,
      setErrorModal,
      setShowErr,
      setOpenHistory,
      setUploadingFiles,
      setErrorMessage,
      setFileS3Link,
      setIsFileUploading,
      uploadingFiles,
      setMessageId,
      chatItem,
      changeModel,
      setChangeModel
    }) => {
      const { uploadToS3 } = useFileUploader();
      const { pathname } = useRouter();
      const { formatMessage } = useIntl();

      const { theme, gptModel, userDetail } = useSelector(
        (state) => state.authReducer
      );
      const { chatModels } = useSelector((state) => state.chatModelsReducer);
      const currentPlan = useSelector(
        (state) => state.planSubscriptionReducer.activePlan
      );
      const { newMessages } = useSelector(
        (state) => state.chatReducer
      );

      const [isDrag, setDrag] = useState<boolean>(true);
      const history = pathname.includes("/chat/history");

      useEffect(() => {
        setDrag(true);
      }, []);

      const documentMaxCountReached = (): boolean => {
        
        const max_count = currentPlan?.attributes?.max_document_chats as number;
        return (
          (userDetail?.user.activeSubscription.document_chat_count as number) >=
          max_count
        );
      };

      const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;

      const handleDragOver = (event: DragEvent<HTMLElement> | undefined) => {
        event?.preventDefault();
      };

      const handleDragLeave = (event: DragEvent<HTMLElement> | undefined) => {
        event?.preventDefault();
        setIsDrag(false);
      };

      const handleDrop = async (event: DragEvent<HTMLElement> | undefined) => {
        event?.preventDefault();

        setOpenHistory!(false);
        const file = event?.dataTransfer?.files?.length
          ? event.dataTransfer.files
          : null;

        if (file) {
          const newFilesArray = Array.from(file);
          const imageFiles = newFilesArray.filter((file) =>
            file.type.startsWith("image/")
          );
          const otherFiles = newFilesArray.filter(
            (file) => !file.type.startsWith("image/")
          );

          if (
            ((newMessages.length > 0 &&
              newMessages[0]?.images &&
              newMessages[0]?.images.length > 0) ||
              (chatItem && chatItem.chat_type === "image_chat")) &&
            otherFiles.length > 0
          ) {
            if (chatPage) setShowErr!(true);
            setErrorModal({
              message: " Document can't be selected for image chat",
              show: true,
            });
          } else if (
            ((newMessages.length > 0 &&
              newMessages &&
              (newMessages[0]?.files?.length ?? 0 > 0)) ||
              (chatItem && chatItem.chat_type === "document")) &&
            imageFiles.length > 0
          ) {
            if (chatPage) setShowErr!(true);
            setErrorModal({
              message: " Image can't be selected for document chat",
              show: true,
            });
          } else if (
            (imageFiles.length > 0 && otherFiles.length > 0) ||
            (uploadingFiles &&
              uploadingFiles.length > 0 &&
              uploadingFiles[0]?.file.type.startsWith("image/") &&
              otherFiles.length > 0) ||
            (uploadingFiles &&
              uploadingFiles.length > 0 &&
              !uploadingFiles[0]?.file.type.startsWith("image/") &&
              imageFiles.length > 0)
          ) {
            if (chatPage) setShowErr && setShowErr(true);
            setErrorModal({
              message:
                "Images and documents can't be selected together. Please select one type at a time.",
              show: true,
            });
          } else if (imageFiles.length > 0) {
            setIsMainScreenOpen!(true);
            if (newMessages.length === 0 && !history && changeModel === true) {
              const imageChatModel = localStorage.getItem('imageChatGptModel');
              if (imageChatModel) {
                localStorage.setItem('GptModel', imageChatModel);
                setChatModel(JSON.parse(imageChatModel));
              }
            }
            setSelectedFile && setSelectedFile((prevSelectedFiles) => {
              const combinedFiles = prevSelectedFiles ? [...prevSelectedFiles, ...imageFiles] : imageFiles;
              if (file && file[0].type.startsWith("image/") && (gptModel?.name === "GPT-3.5-16K" || gptModel?.name === "GPT-4o mini")) {
                if (newMessages.length === 0 && !history) {
                  const imageChatModel = localStorage.getItem('imageChatGptModel');
                  const selectedModel = imageChatModel ? JSON.parse(imageChatModel) : chatModels[1];

                  if (!imageChatModel) {
                    localStorage.setItem('imageChatGptModel', JSON.stringify(selectedModel));
                    localStorage.setItem('GptModel', JSON.stringify(selectedModel));
                  }                
                  setChatModel(selectedModel);
                }
              }

              const total = (uploadingFiles && uploadingFiles.length + imageFiles.length)
              if (combinedFiles.length > 20 && (uploadingFiles && (uploadingFiles?.length > 20 || total && total > 20))) {
                const firstThreeFiles = combinedFiles.slice(0, 20);

                setErrorModal({
                  message: "ImageChat.file.fileLimit",
                  show: true,
                });
                return firstThreeFiles;
              }
              else {
                return combinedFiles;
              }
            });
            setChangeModel?.(false);
            setIsDrag(false)
            setDrag(false);
            const imgFile = convertFileNamesToLowercase(imageFiles);
            await uploadFilesToS3(imgFile);
          } else if (otherFiles.length > 0) {
            if (documentMaxCountReached()) {
              setMessageId!("documentChat.plan.max_count");
              setSelectedFile!(null);
              setIsMainScreenOpen!(true);
              setIsDrag(false);
              setDrag(false);
              return;
            }
            const totalFilesCount = (uploadingFiles ? uploadingFiles.length : 0) + otherFiles.length;
            if (userDetail?.user.activeSubscription?.name === 'Free' && otherFiles?.length > 1 || (userDetail?.user.activeSubscription?.name === 'Free' && uploadingFiles && !uploadingFiles[0]?.file.type.startsWith('image/') && (uploadingFiles?.length > 1 || totalFilesCount > 1))) {
              if (chatPage) { setShowErr && setShowErr(true); }
              if (documentMaxCountReached()) {
                setMessageId!("documentChat.plan.max_count");
                setSelectedFile!(null);
                setIsMainScreenOpen!(true);
                setIsDrag(false);
                setDrag(false);
                return;
              }
              else{
                setMessageId!("documentChat.plan.max_count");
                setSelectedFile!(null);
                setIsMainScreenOpen!(true);
                setIsDrag(false);
                setDrag(false);
                return;
              }
            } else if (
              otherFiles.length > 5 ||
              (uploadingFiles &&
                !uploadingFiles[0]?.file.type.startsWith("image/") &&
                (uploadingFiles?.length > 5 || totalFilesCount > 5))
            ) {
              if (chatPage) {
                setShowErr && setShowErr(true);
              }
              setErrorModal({
                message: "documentChat.file.fileLimit",
                show: true,
              });
            } else {
              setIsMainScreenOpen!(true);
              setSelectedFile &&
                setSelectedFile((prevSelectedFiles) => {
                  const combinedFiles = prevSelectedFiles
                    ? [...prevSelectedFiles, ...otherFiles]
                    : otherFiles;
                  return combinedFiles;
                });
              setIsDrag(false);
              setDrag(false);
              await uploadFilesToS3(otherFiles);
            }
          }
          setChangeModel?.(false);
        }
        setIsDrag(false);
        setDrag(false);
      };

      const uploadFilesToS3 = async (files: File[]) => {
        if (!files[0].type.startsWith("image/")) {
          if (documentMaxCountReached()) {
            setMessageId!("documentChat.plan.max_count");
            setSelectedFile!(null);
            setUploadingFiles!([]);
            setIsMainScreenOpen!(true);
            return;
          }
        }

        const fileArray = Array.from(files);
        const fileType = files[0]?.type.startsWith("image/")
          ? "image"
          : "document";
        const validFiles = fileType === "image"
          ? validateImgFile(
            fileArray,
            setErrorMessage,
            claudAllowedFileSize,
            formatMessage
          )
          : validateFile(
            fileArray,
            setIsMainScreenOpen,
            setErrorModal,
            userDetail,
            setMessageId,
          );

        if (validFiles) {
          setIsFileUploading!(true);
          await uploadToS3({ fileArray, setUploadingFiles, fileType, setIsFileUploading, setFileS3Link });
        }
      };

      const handleClose = () => {
        setIsMainScreenOpen!(true);
        setIsDrag(false);
      };

      return (
        <>
          {!chatPage && <Chat />}
          <div className={styles.container}>
            <div
              className={classNames(styles.header, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            ></div>
            <div
              className={classNames(styles.body, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              {isDrag === true && (
                <div onClick={handleClose}>
                  <Modal
                    size="xl"
                    drag={true}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClose={handleClose}
                  >
                    <label
                      htmlFor="file"
                      className={classNames(styles.dropzone, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      <NewUploadIcon />
                      <div className={styles.message}>
                        <FormattedMessage id="documentChat.dragDrop.new.message" />
                      </div>
                      <div className={styles.validationText}>
                        <FormattedMessage id="doc.drag.drop.start" />
                      </div>
                    </label>
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }
  )
);