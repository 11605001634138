import {
  ReactNode,
  LegacyRef,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useEffect,
} from "react";
import { useEffectOnce } from "react-use";
import styles from "./MainView.module.scss";
import classNames from "classnames";

import { Sidebar } from "../Sidebar";
import { Navbar } from "../Navbar";

import {
  ChatType,
  getCreditLimits,
  IChat,
  startNewChat,
  setChatModel,
  EChatType,
  DeleteS3Link,
  removeMultipleChat,
  setRememberHistoryType,
} from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute, IUploadFile } from "pages/ChatPage";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { useSelector } from "redux/hooks";

interface IProps {
  sendMessageLoading?: boolean;
  isAnswerComplete?: boolean;
  onChangeChat?: () => void;
  children?: ReactNode;
  contentContainerRef?: LegacyRef<HTMLDivElement>;
  isMainScreenOpen?: boolean;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  docuemntModel?: boolean;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  setShare?: Dispatch<SetStateAction<boolean>>;
  setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
  setChatItem?: Dispatch<SetStateAction<IChat | undefined>>;
  searchQuery?: string;
  setSearchQuery?: Dispatch<SetStateAction<string>>;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  updateChatModel?: (modalType: EChatType) => void;
  setIsDrag?: Dispatch<SetStateAction<boolean>>;
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  isFileUploading?: boolean;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  controllerRef?: React.MutableRefObject<AbortController | null | undefined>;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  isAllowUploadFile?: boolean;
  fileS3Link?: string[];
}

export const MainView = ({
  sendMessageLoading,
  isAnswerComplete,
  onChangeChat,
  children,
  contentContainerRef,
  isMainScreenOpen,
  setIsMainScreenOpen,
  docuemntModel,
  setChatHistory,
  chatHistoryPagination,
  setShare,
  setSelectedMessages,
  setChatItem,
  searchQuery,
  setSearchQuery,
  setChatSetting,
  updateChatModel,
  setIsDrag,
  setSelectedFile,
  isFileUploading,
  setOpenHistory,
  controllerRef,
  uploadingFiles,
  setUploadingFiles,
  setFileS3Link,
  isAllowUploadFile,
  fileS3Link,
}: IProps) => {
  const { isOpen } = useToggleSidebar();
  const { triggerNotification } = useAppNotification();
  const { includeRoute, push, pathname } = useRouter();

  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { newMessages, messages,
    PrivateChat
  } = useSelector((state) => state.chatReducer);
  const { gptModel, theme } = useSelector((state) => state.authReducer);

  const isShareChat = window.location.pathname.includes("share-chat");
  const history = pathname.includes("/chat/history");
  const containerRef = useRef<HTMLDivElement>(null);
  const [allowDrag, setAllow] = useState<boolean>(false);

  useEffectOnce(() => {
    if (!isShareChat) {
      getCreditLimits().catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
    }
  });

  const handleDeleteS3Link = () => {
    fileS3Link?.map((file) => {
      DeleteS3Link(file)
    })
  }

  useEffect(() => {
    const isPrivateChatActive = JSON.parse(sessionStorage.getItem('privateChatActive') || 'false');
    const PrivateChatId = Number(sessionStorage.getItem("PrivateChatId"));

    if (isPrivateChatActive && PrivateChatId && PrivateChat) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
       
        const currentPath = window.location.pathname;
        const pathSegments = currentPath.split('/');
        const chatIdFromUrl = Number(pathSegments[pathSegments.length - 1]) || PrivateChatId;

        if (chatIdFromUrl) {
          const updatedChatHistory = chatHistoryPagination?.filter((chat) => chat.id !== chatIdFromUrl);
          setChatHistory!(updatedChatHistory);
          sessionStorage.setItem('redirectedFromPrivateChat', 'true');
          removeMultipleChat([chatIdFromUrl]);
           // Show confirmation dialog if user tries to close/refresh the tab
          event.preventDefault();
          event.returnValue = ''; // Some browsers require this for showing a confirmation
          sessionStorage.removeItem('privateChatActive');
          sessionStorage.removeItem('PrivateChatId');
        }
      };
      // Attach event listener for 'beforeunload'
      window.addEventListener("beforeunload", handleBeforeUnload);

      // Cleanup function
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [chatHistoryPagination, setChatHistory, removeMultipleChat]);



  // useEffect(() => {

  //   const isPrivateChatActive = JSON.parse(localStorage.getItem('privateChatActive') || 'false');
  //   const PrivateChatId = Number(localStorage.getItem("PrivateChatId"));

  //   if (isPrivateChatActive && PrivateChatId) {
  //     const handleBeforeUnload = (event: BeforeUnloadEvent) => {

  //       const currentPath = window.location.pathname;
  //       const pathSegments = currentPath.split('/');
  //       const chatIdFromUrl = Number(pathSegments[pathSegments.length - 1]) || PrivateChatId;

  //       if (chatIdFromUrl) {
  //         const updatedChatHistory = chatHistoryPagination?.filter((chat) => chat.id !== chatIdFromUrl);
  //         setChatHistory!(updatedChatHistory);
  //         sessionStorage.setItem('redirectedFromPrivateChat', 'true');
  //         removeMultipleChat([chatIdFromUrl]); 
  //         localStorage.removeItem('privateChatActive');
  //         localStorage.removeItem('PrivateChatId');
  //         event.preventDefault();
  //          event.returnValue = '';
  //       }
  //     }; window.addEventListener("beforeunload", handleBeforeUnload);
  //     // Cleanup function 
  //     return () => {
  //       localStorage.removeItem('privateChatActive');
  //       localStorage.removeItem('PrivateChatId');
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //     };
  //   }
  // }, [chatHistoryPagination,setChatHistory]);

  const onStartNewChat = ({ toChat, PrivateChat }: { toChat: boolean, PrivateChat?: boolean }) => {
    setShare && setShare(false);
    setUploadingFiles?.([]);
    setSelectedFile?.(null);
    setFileS3Link?.([]);
    setSelectedMessages && setSelectedMessages([]);
    const currentPath = window.location.pathname;
    const pathSegments = currentPath.split('/');
    const chatIdFromUrl = pathSegments[pathSegments.length - 1];
    if (!sendMessageLoading || isAnswerComplete) {
      if (toChat) {
        setRememberHistoryType("")
        const isPrivateChatActive = JSON.parse(localStorage.getItem('previousPrivateChat') || 'false');
        if (Number(chatIdFromUrl) && isPrivateChatActive !== false && PrivateChat !== true) {
          const updatedChatHistory = chatHistoryPagination && chatHistoryPagination.filter(
            (chat) => chat.id !== Number(chatIdFromUrl)
          );
          setChatHistory!(updatedChatHistory);
          removeMultipleChat([Number(chatIdFromUrl)])
          // localStorage.removeItem('privateChatActive');
          sessionStorage.removeItem('PrivateChatId');
        }
        handleDeleteS3Link()
        push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
        setIsMainScreenOpen?.(true);
        setSelectedFile && setSelectedFile(null);
        const GPTModal = localStorage.getItem('GptModel');
        if (GPTModal) {
          const gptModel = JSON.parse(GPTModal);
          setChatModel(gptModel);
        }
        else if (
          gptModel?.type?.includes(ChatType.image) &&
          !gptModel?.type?.includes(ChatType.image_chat)
        ) {
          setChatModel(chatModels[0]);
        }
      }
      startNewChat();
      onChangeChat?.();
    }
  };

  const onSelectChatItem = (chatItem: IChat | undefined) => {
    setRememberHistoryType(chatItem?.chat_type ?? "")
    controllerRef?.current?.abort();
    setShare?.(false);
    setUploadingFiles?.([]);
    setSelectedMessages?.([]);
    setFileS3Link?.([]);
    setChatItem && setChatItem(chatItem);
    handleDeleteS3Link();
    push(`/${RoutePaths.Chat}/${ChatRoute.History}/${chatItem?.id}`);
    onChangeChat?.();
    setIsMainScreenOpen?.(true);
    setSelectedFile && setSelectedFile(null);
    if (
      gptModel &&
      !gptModel?.type?.includes(chatItem?.chat_type as EChatType)
    ) {
      setChatModel(
        chatModels?.filter((item) =>
          item?.type?.includes(chatItem?.chat_type as EChatType)
        )?.[0]
      );
    }
    if (chatItem?.model) {
      const filteredChatModels = chatModels?.filter(
        (item) => item?.name === chatItem?.model?.name
      );
      setChatModel(filteredChatModels[0]);
    }
  };

  useEffect(() => {
    if (
      newMessages[0]?.images?.length > 0 ||
      (newMessages[0]?.files && newMessages[0]?.files.length > 0)
    )
      setAllow(true);
    else setAllow(false);
  }, [newMessages]);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAllowUploadFile) {
      setIsDrag!(true);
      if (!allowDrag || history) {
        setIsMainScreenOpen?.(false);
        updateChatModel?.("document");
      }
    }
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.relatedTarget === null) {
      setIsDrag!(false);
      setIsMainScreenOpen?.(true);
    }
  };

  const isChat = includeRoute(RoutePaths.Chat);
  const isSettings = includeRoute(RoutePaths.Settings);
  const isAccount = includeRoute(RoutePaths.Account);
  const isIChatFooter = pathname.includes("chat/new");

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%" }}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <div
        className={classNames(styles.container, {
          [styles.lightSettings]:
            theme === "light" && (isSettings || isShareChat),
          [styles.lightChat]: theme === "light" && isChat && !isShareChat,
          [styles.dark]: theme === "dark",
          [styles.chatContainer]: isChat,
          [styles.settingContainer]: isSettings,
          [styles.isSidebarOpen]: !isOpen && !isShareChat,
          [styles.isShareChat]: isShareChat && theme === "light",
        })}
      >
        {!isShareChat && (
          <Sidebar
            onSelectChatItem={onSelectChatItem}
            onStartNewChat={onStartNewChat}
            isMainScreenOpen={isMainScreenOpen}
            setChatHistory={setChatHistory}
            chatHistoryPagination={chatHistoryPagination}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setOpenHistory={setOpenHistory}
            sendMessageLoading={sendMessageLoading}
            isAnswerComplete={isAnswerComplete}
          />
        )}

        <Navbar
          onSelectChatItem={onSelectChatItem}
          onStartNewChat={onStartNewChat}
          isMainScreenOpen={isMainScreenOpen}
          docuemntModel={docuemntModel}
          setChatSetting={setChatSetting}
          isFileUploading={isFileUploading}
          uploadingFiles={uploadingFiles}
        />

        <div
          ref={contentContainerRef}
          className={classNames(styles.content, {
            [styles.light]: theme === "light" && isChat,
            [styles.dark]: theme === "dark" && isChat,
            [styles.isSidebarOpen]: isOpen && !isShareChat,
            [styles.chatContent]: isChat,
            [styles.isShareChat]: isShareChat,
            [styles.IGChatContent]:
              gptModel?.type?.includes(ChatType.image) &&
              !gptModel?.type?.includes(ChatType.image_chat) &&
              isChat,
            [styles.settingsContent]: isSettings,
            [styles.settingsContentlight]:
              isSettings && isAccount && theme === "light",
            [styles.settingsContentdark]:
              isSettings && isAccount && theme === "dark",
            [styles.shareChat]: isShareChat && theme === "light",
            [styles.isSideBarClose]: !isOpen,
            [styles.IChatContent]:
              (newMessages[0]?.images && newMessages[0]?.images.length > 0) ||
              (messages[0]?.images &&
                messages[0]?.images.length > 0 &&
                !isIChatFooter),
            [styles.IUplodingFile]:
              uploadingFiles &&
              uploadingFiles.length > 0 &&
              (newMessages.length > 0 || !isIChatFooter),
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
