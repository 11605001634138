import { useEffect, useState, Dispatch, SetStateAction, useRef, useCallback } from "react";
import { ChatItem } from "../ChatItem/ChatItem";
import { ChatSearchItem } from "../ChatItem/ChatSearchItem";
import { deleteAllConversation, getAllChats, IChat, removeMultipleChat } from "redux/actions";
import { LoadingPage } from "components";
import styles from "../../ChatSidebar.module.scss";
import { Spinner } from "components";
import { FormattedMessage } from "react-intl";
import { useAppNotification } from "hooks/services/AppNotification";
import { DownArrow } from "../icons/DownArrow";
// import { DeleteHistory } from "../icons/DeleteHistory";
import { HistoryIcon } from "views/layout/Navbar/components/icons/HistoryIcon";
import { DeleteChatConfirm } from "../DeleteChatConfirm";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute } from "pages/ChatPage";
import classNames from "classnames";
import { DeleteIcon, RemoveIcon } from "../icons";
import chatItemStyle from '../ChatItem/ChatItem.module.scss';
import React from "react";
import { useSelector } from "redux/hooks";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
  setChatHistory?: Dispatch<SetStateAction<any>>;
  chatHistoryPagination?: IChat[];
  searchQuery: string;
  toggleSidebar?: any;
  width?: number;
  setSelectedItem: (chatItem: IChat | undefined) => void;
  selectedItem?: IChat | undefined;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
}

export const ChatItemList = ({
  onSelectChatItem,
  setChatHistory,
  chatHistoryPagination,
  searchQuery,
  toggleSidebar,
  width,
  setSelectedItem,
  selectedItem,
  setOpenHistory,
  onStartNewChat,
}: IProps) => {

  const { triggerNotification } = useAppNotification();
  const contentRef = useRef<HTMLDivElement>(null);

  const [isloading, setIsloading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [isloadingMore, setIsloadingMore] = useState<boolean>(false);
  const [perPage, setPerPage] = useState(0);
  const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
  const [chatDelConfirm, setChatDelConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAllChat, setSelectAllChats] = useState<boolean>(false);
  const { push } = useRouter();

  const [isSelectAllClicked, setIsSelectAllClicked] = useState<boolean>(false);

  const showDelete = selectedChatIds.length >= 2;
  const { PrivateChat} = useSelector(
    (state) => state.chatReducer
  );
  useEffect(() => {
    setPerPage(0);
    const contentHeight = contentRef?.current?.clientHeight || 0;
    const newPerPage = Math.floor(contentHeight / 28);
    setPerPage(newPerPage);
  }, [contentRef?.current?.clientHeight]);

  useEffect(() => {
    if (perPage && !chatDelConfirm && !isSelectAllClicked) {
      setIsloading(true);
      const delayDebounce = setTimeout(() => {
        setIsloading(true);
        getAllChats({ search: searchQuery, page: 1, perPage })
          .then((resp: any) => {
            setChatHistory!(resp?.data || []);
            setIsloading(false);
            setPage(1 + 1);
            setLastPage(resp?.last_page);
          })
          .catch((err: any) => {
            setIsloading(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }, 1500);
      return () => clearTimeout(delayDebounce);
    }
  }, [searchQuery, perPage]);

  useEffect(() => {
    if (selectAllChat === true) {
      const allChatIds = chatHistoryPagination?.map(chat => chat.id) || [];
      setSelectedChatIds(allChatIds);
    }
  }, [selectAllChat, chatHistoryPagination])

  const loadMoreChats = () => {
    setIsloadingMore(true);
    getAllChats({ search: searchQuery, page: page, perPage })
      .then((newChats: any) => {
        setChatHistory!((prevChats: any) => [...prevChats, ...newChats?.data]);
        setPage(page + 1);
        setLastPage(newChats?.last_page);
      })
      .catch((error) => console.error("Error loading more chats:", error))
      .finally(() => setIsloadingMore(false));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (page > lastPage) {
    } else if (searchQuery === '') {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      const scrollPositionFromBottom =
        scrollHeight - (scrollTop + clientHeight);
      const loadThreshold = 1;
      if (scrollPositionFromBottom <= loadThreshold && !isloadingMore) {
        loadMoreChats();
      }
    }
  };

  const handleSelectId = (id: number) => {
    setSelectedChatIds(prevSelectedChatIds => {
      if (prevSelectedChatIds.includes(id)) {
        return prevSelectedChatIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedChatIds, id];
      }
    });
    setIsSelectAllClicked(true);
    setSelectAllChats(false);
  };

  const onSelectChat = (chatItem: IChat | undefined) => {
    setOpenHistory!(true);
    setSelectedItem(chatItem);
    onSelectChatItem?.(chatItem);
    if (width && width <= 768) {
      setTimeout(() => toggleSidebar(), 500);
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  const handleCancel = useCallback(() => {
    setSelectedChatIds([]);
    setChatDelConfirm(false);
    setIsSelectAllClicked(true);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    if (selectAllChat) {
      deleteAllConversation().then(() => {
        setChatHistory!([]);
        loadMoreChats();
        onStartNewChat?.({ toChat: true });
        setLoading(false);
        setChatDelConfirm(false);
        setSelectedChatIds([]);
        setSelectAllChats(false);
        setIsSelectAllClicked(false);
      })
        .catch((err) => {
          setLoading(false);
          setChatDelConfirm(false);
          setSelectedChatIds([]);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    }
    else {
      removeMultipleChat(selectedChatIds)
        .then(() => {
          const updatedChatHistory = chatHistoryPagination && chatHistoryPagination.filter(
            (chatItem) => !selectedChatIds.includes(chatItem.id)
          );
          setChatHistory!(updatedChatHistory);
          setLoading(false);
          setChatDelConfirm(false);
          setSelectedChatIds([]);
          if (page > lastPage) {
          }
          else {
            loadMoreChats();
          }
          setIsSelectAllClicked(true);
          const currentPath = window.location.pathname;

          const pathSegments = currentPath.split('/');
          const chatIdFromUrl = pathSegments[pathSegments.length - 1];

          if (selectedItem && selectedChatIds.includes(selectedItem.id)) {
            push(`/${RoutePaths.Chat}/${ChatRoute.New}`); // Redirect to the new chat page
          }

          if (selectedChatIds.includes(Number(chatIdFromUrl))) {
            onStartNewChat?.({ toChat: true });
          }
        })
        .catch((err) => {
          setLoading(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    }
  }

  // Memoized function to handle "Select All" logic
  const handleSelectAllChats = useCallback((isChecked: boolean) => {
    if (isChecked) {
      const allChatIds = chatHistoryPagination?.map(chat => chat.id) || [];
      setSelectedChatIds(allChatIds);
    } else {
      setSelectedChatIds([]);
    }
    setSelectAllChats(isChecked);
    setIsSelectAllClicked(isChecked);
  }, [chatHistoryPagination]);

  return (
    <>
     <div
      className={classNames(styles.historyIconContainer, {
        [styles.isbackdrop]:PrivateChat,
      })}>
        <HistoryIcon />
        <span className={styles.historyHeading}><FormattedMessage id="sidebar.chat.history" /></span>
        <div className={classNames(styles.delChat)}>
          <div onClick={toggleAccordion}>
            <DownArrow />
          </div>
        </div>
      </div>
      {chatDelConfirm && (
        <DeleteChatConfirm onCancel={handleCancel} onSubmit={handleSubmit} loading={loading} />
      )}

      {showDelete && (

        <div className={`flex pl-[37px] items-center py-1 ${classNames({
          [styles.isbackdrop]:PrivateChat,
        })}`}>
          <div className={chatItemStyle.check}>
            <input type="checkbox"
              checked={selectAllChat}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={chatItemStyle.visibleCheckbox}
              onChange={(e) => handleSelectAllChats(e.target.checked)}
            />
          </div>
          <span className="text-xs font-medium opacity-90	text-white leading-[28px] pl-1 w-[56%]"><FormattedMessage id="sidebar.chat.select.all" /></span>
          <div className="flex justify-between absolute right-6 gap-3">
            <div data-testid='confirm-edit-btn' className="cursor-pointer" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); setChatDelConfirm(true)
            }}>
              <DeleteIcon />
            </div>

            <div data-testid='cancel-edit-btn ' className="cursor-pointer" onClick={handleCancel}>
              <RemoveIcon />
            </div>
          </div>
        </div>
      )}

      {/* <div className={styles.content} onScroll={handleScroll} ref={contentRef}> */}
      <div className={classNames(styles.content, {
        [styles.isbackdrop]: PrivateChat,
      })} onScroll={handleScroll} ref={contentRef}>
        {isAccordionOpen && (
          <>
            {isloading && <LoadingPage smallLoader />}
            {!isloading && chatHistoryPagination?.length === 0 && (
              <div
                className={styles.emptySearchMessage}
              >
                <FormattedMessage id="sidebar.chat.noChat.message" />
              </div>
            )}

            {!isloading &&
              chatHistoryPagination &&
              chatHistoryPagination.length > 0 &&
              chatHistoryPagination.map((chatItem, key) =>
                searchQuery && searchQuery.length > 0 ? (
                  <ChatSearchItem
                    key={`topic-${key}`}
                    searchQuery={searchQuery}
                    chatItem={chatItem}
                    onSelectChat={onSelectChat}
                  />
                ) : (
                  <ChatItem
                    key={`topic-${key}`}
                    chatItem={chatItem}
                    selectedItem={selectedItem}
                    onSelectChat={onSelectChat}
                    searchQuery={searchQuery}
                    setChatHistory={setChatHistory || (() => { })}
                    chatHistoryPagination={chatHistoryPagination}
                    handleSelectId={handleSelectId}
                    selectedChatIds={selectedChatIds}
                    setSelectedChatIds={setSelectedChatIds}
                    onStartNewChat={onStartNewChat}
                    setSelectedItem={ setSelectedItem}
                  />
                )
              )}
            {isloadingMore && (
              <div className="flex items-center justify-center">
                <Spinner small />
              </div>
            )}
          </>
        )
        }
      </div>
      {/* </span> */}
    </>
  );
};