import { memo, Fragment, useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import styles from "./answerContainer.module.scss";
import classNames from "classnames";
import { UserName } from "../userName";

import { IMessage, IShareChatMessage } from "redux/actions";
import { useSelector } from "redux/hooks";
import React from "react";

import useRouter from "hooks/useRouter";
import FileLink from "./FileLink";
import { ImagePreview } from "./ImagePreview";
import { QuestionContainer } from "./questionContainer";

interface IProps {
  message?: IMessage;
  messageRef?: React.LegacyRef<HTMLDivElement>;
  shareMessage?: IShareChatMessage;
  shareChat?: boolean;
  chatItem?: IMessage[];
  searchQuery?: string;
  setHighlighted?: React.Dispatch<React.SetStateAction<boolean>>;
  highlighted?: boolean;
  handleSubmit?: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  setImage?: React.Dispatch<React.SetStateAction<string[]>>;
  setFile?: React.Dispatch<React.SetStateAction<string[]>>;
  assistantMessage?: boolean;
}

export const Question = memo(
  ({
    message,
    messageRef,
    searchQuery,
    shareMessage,
    shareChat,
    handleSubmit,
    setMessage,
    setImage,
    setFile,
    assistantMessage,
  }: IProps) => {
    const { userDetail,
      // theme
    } = useSelector((state) => state.authReducer);
    const { includeRoute } = useRouter();

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const isHighlighted = searchQuery !== "";
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
      setLoading(false);
    };
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      textAreaRef.current?.focus();

      if (textAreaRef.current) {
        const { value } = textAreaRef.current;
        textAreaRef.current.setSelectionRange(value.length, value.length);
      }
      return () => {
        setMessage && setMessage("");
      };
    }, [isEditMode === true]);

    useEffect(() => {
      const intervalID = setTimeout(() => {
        if (isCopied) setIsCopied(false);
      }, 500);

      return () => clearInterval(intervalID);
    }, [isCopied]);

    const isShareChat = includeRoute("/share-chat/");

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit && handleSubmit();
        setIsEditMode(false);
      }
    };

    useEffect(() => {
      if (isEditMode === true) {
        if (message && message?.images && message?.images.length > 0) {
          const images = message?.images.map((img) => {
            return img.path
          })
          setImage!(images);
        }
        else if (message?.files && message.files.length > 0) {
          const files = message.files.map((file) => file.path).filter((path): path is string => path !== null);
          setFile!(files);
        }
        else {
          setFile!([]);
          setImage!([]);
        }
      }
    }, [isEditMode === true])

    return (
      <Fragment>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div ref={messageRef} className={styles.container}>
            <div className={styles.leftContainer}>
              <UserName
                name={userDetail?.user?.name?? ""}
                email={userDetail?.user?.email?? ""}
              />

              {((message?.files?.length ?? 0) > 0 || (shareMessage?.files?.length ?? 0) > 0) ? (
                <div className={classNames(styles.container, styles.additionalContainer, {
                  [styles.sharedContainer]: (shareMessage?.files?.length ?? 0) > 0 && shareMessage?.content
                })}>
                  <FileLink file={message?.files || shareMessage?.files || []} contentFile={true} />
                </div>
              ) : (
                (((message?.images?.length ?? 0) > 0 && !shareChat) || ((shareMessage?.images?.length ?? 0) > 0 && isShareChat)) ? (
                  <div className={classNames(styles.imagesContainer, styles.additionalimages, {
                    [styles.imageContent]: true,
                    [styles.sharedimageContainer]: (message?.images?.length ?? 0) > 0 && !shareChat,
                  })}>
                    <ImagePreview images={message?.images || shareMessage?.images || []} loading={loading} handleImageLoad={handleImageLoad} />
                  </div>
                ) :
                  <QuestionContainer isEditMode={isEditMode}
                    message={message}
                    searchQuery={searchQuery}
                    setMessage={setMessage}
                    shareChat={shareChat}
                    shareMessage={shareMessage}
                    textAreaRef={textAreaRef}
                    setIsCopied={setIsCopied} isShareChat={isShareChat}
                    isHighlighted={isHighlighted}
                    setIsEditMode={setIsEditMode}
                    isCopied={isCopied} handleKeyDown={handleKeyDown} />
              )}
            </div>
          </div>
          {(message?.content || shareMessage?.content) && ((message?.files?.length ?? 0) > 0 || ((shareMessage?.files?.length ?? 0) > 0) || (((message?.images?.length ?? 0)) > 0) || ((shareMessage?.images?.length ?? 0) > 0)) && !assistantMessage && (
            <QuestionContainer isEditMode={isEditMode}
              message={message}
              searchQuery={searchQuery}
              setMessage={setMessage}
              shareChat={shareChat}
              shareMessage={shareMessage}
              textAreaRef={textAreaRef}
              setIsCopied={setIsCopied}
              isShareChat={isShareChat}
              isHighlighted={isHighlighted}
              setIsEditMode={setIsEditMode}
              isCopied={isCopied} handleKeyDown={handleKeyDown} />
          )}

          {isEditMode && (
            <div className={styles.actionContainer}>
              <div className="flex items-center justify-end gap-2 w-full max-w-[800px]">
                <div className="flex items-center gap-2">
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit && handleSubmit();
                      setIsEditMode(false);
                    }}
                    style={{
                      background:
                        "linear-gradient(92.31deg, #8C49F7 0%, #6C53FF 100%)",
                    }}
                    className="px-[20px] py-[10px] text-[12px] md:text-[15px] font-[600] text-white rounded-[100px] cursor-pointer"
                  >
                    <FormattedMessage id={"answer.edit.save"} />
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setIsEditMode(false);
                    }}
                    style={{ border: "1px solid rgba(31, 31, 31, 0.2)" }}
                    className="px-[20px] py-[10px] bg-white text-[12px] md:text-[15px] font-[600] text-black rounded-[100px] cursor-pointer"
                  >
                    <FormattedMessage id={"answer.edit.cancel"} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </Fragment>
    );
  }
);